<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#FC930A" />
    <path
      d="M35.9497 22.2C35.1122 21.275 33.8497 20.7375 32.0997 20.55V19.6C32.0997 17.8875 31.3747 16.2375 30.0997 15.0875C28.8122 13.9125 27.1372 13.3625 25.3997 13.525C22.4122 13.8125 19.8997 16.7 19.8997 19.825V20.55C18.1497 20.7375 16.8872 21.275 16.0497 22.2C14.8372 23.55 14.8747 25.35 15.0122 26.6L15.8872 33.5625C16.1497 36 17.1372 38.5 22.5122 38.5H29.4872C34.8622 38.5 35.8497 36 36.1122 33.575L36.9872 26.5875C37.1247 25.35 37.1497 23.55 35.9497 22.2ZM25.5747 15.2625C26.8247 15.15 28.0122 15.5375 28.9372 16.375C29.8497 17.2 30.3622 18.375 30.3622 19.6V20.475H21.6372V19.825C21.6372 17.6 23.4747 15.4625 25.5747 15.2625ZM21.5247 27.4375H21.5122C20.8247 27.4375 20.2622 26.875 20.2622 26.1875C20.2622 25.5 20.8247 24.9375 21.5122 24.9375C22.2122 24.9375 22.7747 25.5 22.7747 26.1875C22.7747 26.875 22.2122 27.4375 21.5247 27.4375ZM30.2747 27.4375H30.2622C29.5747 27.4375 29.0122 26.875 29.0122 26.1875C29.0122 25.5 29.5747 24.9375 30.2622 24.9375C30.9622 24.9375 31.5247 25.5 31.5247 26.1875C31.5247 26.875 30.9622 27.4375 30.2747 27.4375Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
