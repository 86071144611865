import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("request");
import {
  FETCH_PROFESSIONAL_SUCCESS,
  FETCH_PROFESSIONAL_FAILURE,
  FETCH_PROFESSIONAL_LOADING,
  PROFESSIONAL_STATUS_CHANGED,
  FETCH_PRO_SERVICES_SUCCESS,
  FETCH_PRO_SERVICES_DELETED,
} from "./mutation_types";

const state = {
  professionals: {
    mdCount: 50,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  services: {
    mdCount: 50,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  professional: null,
  loading: false,
  errors: [],
};
const getters = {
  professionals(state) {
    return state.professionals;
  },
  services(state) {
    return state.services;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_PROFESSIONAL_SUCCESS](state, data) {
    state.professionals = data;
  },
  [FETCH_PROFESSIONAL_LOADING](state, status) {
    state.loading = status;
  },
  [FETCH_PRO_SERVICES_SUCCESS](state, data) {
    state.services = data;
  },
  [PROFESSIONAL_STATUS_CHANGED](state, { approval_status, id }) {
    state.professionals.mdData = state.professionals.mdData.map(
      (professional) => {
        if (professional.id == id) {
          professional.approval_status = approval_status;
        }
        return professional;
      }
    );
  },
  [FETCH_PRO_SERVICES_DELETED](state, id) {
    state.services.mdData = state.services.mdData.filter(
      (service) => service.id !== id
    );
  },
};
const actions = {
  async getProfessionals({ commit }, params) {
    try {
      commit(FETCH_PROFESSIONAL_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getProfessionals(params);
      commit(FETCH_PROFESSIONAL_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PROFESSIONAL_LOADING, false);
    } catch (error) {
      // console.log(error);
      commit(FETCH_PROFESSIONAL_LOADING, false);
    }
  },

  async updateProfessionalStatus({ commit }, data) {
    try {
      await PR.updateProfessionalStatus(data);
      commit(PROFESSIONAL_STATUS_CHANGED, data);
    } catch (err) {
      Promise.reject(err);
    }
  },
  async getProfessionalServices({ commit }, params) {
    try {
      commit(FETCH_PROFESSIONAL_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getProfessionalServices(params);
      commit(FETCH_PRO_SERVICES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PROFESSIONAL_LOADING, false);
    } catch (err) {
      commit(FETCH_PROFESSIONAL_LOADING, false);

      Promise.reject(err);
    }
  },

  async createProService(_, data) {
    try {
      let response = await PR.createProService(data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateProService(_, data) {
    try {
      let response = await PR.updateProService(data.id, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getProServiceData() {
    try {
      let response = await PR.getProServiceData();
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async proService(_, $id) {
    try {
      let response = await PR.proService($id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteService({ commit }, id) {
    try {
      let response = await PR.deleteProService(id);
      commit(FETCH_PRO_SERVICES_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateServiceStatus(_, data) {
    try {
      let response = await PR.updatePServiceStatus(data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
