import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("request");
import {
  FETCH_HANDYMEN_SUCCESS,
  FETCH_HANDYMEN_FAILURE,
  FETCH_HANDYMEN_LOADING,
  HANDYMEN_STATUS_CHANGED,
  FETCH_HANDYMEN_SERVICES_SUCCESS,
  FETCH_HANDYMEN_SERVICES_DELETED,
} from "./mutation_types";

const state = {
  handymen: {
    mdCount: 50,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  services: {
    mdCount: 50,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  handyman: null,
  loading: false,
  errors: [],
};
const getters = {
  handymen(state) {
    return state.handymen;
  },
  services(state) {
    return state.services;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_HANDYMEN_SUCCESS](state, data) {
    state.handymen = data;
  },
  [FETCH_HANDYMEN_LOADING](state, status) {
    state.loading = status;
  },
  [FETCH_HANDYMEN_SERVICES_SUCCESS](state, data) {
    state.services = data;
  },
  [HANDYMEN_STATUS_CHANGED](state, { approval_status, id }) {
    state.handymen.mdData = state.handymen.mdData.map((handyman) => {
      if (handyman.id == id) {
        handyman.approval_status = approval_status;
      }
      return handyman;
    });
  },
  [FETCH_HANDYMEN_SERVICES_DELETED](state, id) {
    state.services.mdData = state.services.mdData.filter(
      (service) => service.id !== id
    );
  },
};
const actions = {
  async getHandymen({ commit }, params) {
    try {
      commit(FETCH_HANDYMEN_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getHandymen(params);
      commit(FETCH_HANDYMEN_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_HANDYMEN_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_HANDYMEN_LOADING, false);
    }
  },

  async updateHandymanStatus({ commit }, data) {
    try {
      await PR.updateHandymanStatus(data);
      commit(HANDYMEN_STATUS_CHANGED, data);
    } catch (err) {
      Promise.reject(err);
    }
  },

  async getHandymanServices({ commit }, params) {
    try {
      commit(FETCH_HANDYMEN_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getHandymanServices(params);
      commit(FETCH_HANDYMEN_SERVICES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_HANDYMEN_LOADING, false);
    } catch (err) {
      commit(FETCH_HANDYMEN_LOADING, false);

      Promise.reject(err);
    }
  },

  async createHandymanService(_, data) {
    try {
      let response = await PR.createHandymanService(data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateHandymanService(_, data) {
    try {
      let response = await PR.updateHandymanService(data.id, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getHandymanServiceData() {
    try {
      let response = await PR.getHandymanServiceData();
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async handymanService(_, $id) {
    try {
      let response = await PR.handymanService($id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteService({ commit }, id) {
    try {
      let response = await PR.deleteHandymanService(id);
      commit(FETCH_HANDYMEN_SERVICES_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateServiceStatus(_, data) {
    try {
      let response = await PR.updateHServiceStatus(data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
