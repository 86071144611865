import { axios, url } from "@/plugin/axios";

export default {
  getFaqs(params) {
    return axios.get(`${url}faq/admin`, { params });
  },

  getFaq(id) {
    return axios.get(`${url}faq/admin/${id}`);
  },

  getFormData() {
    return axios.get(`${url}faq/admin/form-data`);
  },

  createFaq(data) {
    return axios.post(`${url}faq/admin`, data);
  },

  updateFaq(id, data) {
    return axios.patch(`${url}faq/admin/${id}`, data);
  },
  deleteFaq(id) {
    return axios.delete(`${url}faq/admin/${id}`);
  },

  getCategories(params) {
    return axios.get(`${url}faq/admin/categories`, { params });
  },

  getCategory(id) {
    return axios.get(`${url}faq/admin/categories/${id}`);
  },
  createCategory(data) {
    return axios.post(`${url}faq/admin/categories`, data);
  },

  updateCategory(id, data) {
    return axios.patch(`${url}faq/admin/categories/${id}`, data);
  },

  deleteCategory(id) {
    return axios.delete(`${url}faq/admin/categories/${id}`);
  },
};
