<template>
  <div class="order-card">
    <div class="title">
      <h4>{{ title }}</h4>
    </div>

    <div class="content">
      <div class="subtitle">
        <h4>{{ total }}</h4>
        <p>Total order</p>
      </div>

      <div class="subtitle">
        <h3>{{ total_month }}</h3>
        <p>This month</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "stats-card-new",
  props: {
    title: {
      type: String,
      default: "",
    },
    total: {
      type: String,
      default: "",
    },
    total_month: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  padding: 14px;
  position: relative;
  height: 182px;
  min-width: 150px;

  &:last-of-type {
    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 38px;
    height: 123px;
    width: 1px;
    background: #f2f2f2;
  }
  .content {
    margin-left: 32px;
  }

  .title {
    h4 {
      color: #808080;
      font-size: 12px;
      font-weight: 500;
      line-height: 184%;
    }
  }
  .subtitle {
    color: #212121;
    h3,
    h4 {
      margin: 0px;
      font-weight: 600;
      line-height: 184%;
    }
    h4 {
      font-size: 18px;
    }
    h3 {
      font-size: 14px;
    }
  }
  p {
    color: #808080;
    font-size: 10px;
  }
  .icon {
    position: absolute;
    top: -26px;
    right: 15px;
  }
  .action {
    position: absolute;
    bottom: 12px;
    right: 15px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 184%; /* 18.4px */

    a {
      color: #ed1c24 !important;
      text-decoration-line: underline;
      &:hover {
        text-decoration-line: underline !important;
      }
    }
  }
}
</style>
