import { axios, url } from "@/plugin/axios";

export default {
  createAdvert(data) {
    return axios.post(`${url}advertisement/admin/create`, data);
  },
  updateAdvert(id, data) {
    return axios.post(`${url}advertisement/admin/update/${id}`, data);
  },

  createSection(data) {
    return axios.post(`${url}advertisement/admin/sections/create`, data);
  },
  updateSection(id, data) {
    return axios.patch(`${url}advertisement/admin/sections/update/${id}`, data);
  },
  adverts(data) {
    return axios.get(`${url}advertisement/admin`, { params: data });
  },

  getAdvert(id) {
    return axios.get(`${url}advertisement/admin/${id}`);
  },
  getSection(id) {
    return axios.get(`${url}advertisement/admin/${id}`);
  },
  sections(data) {
    return axios.get(`${url}advertisement/admin/sections`, { params: data });
  },
  getSection(id) {
    return axios.get(`${url}advertisement/admin/sections/${id}`);
  },

  deleteAdvert(id) {
    return axios.delete(`${url}advertisement/admin/${id}`);
  },

  deleteSection(id) {
    return axios.delete(`${url}advertisement/admin/sections/${id}`);
  },
  getMetaData() {
    return axios.get(`${url}advertisement/admin/metadata`);
  },
};
