import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("page");
import {
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAILURE,
  FETCH_PAGE_LOADING,
  PAGE_STATUS_CHANGED,
  FETCH_PAGE_DELETED,
  FETCH_PAGE_SECTION_SUCCESS,
  FETCH_PAGE_SECTION_FAILURE,
  FETCH_PAGE_SECTION_DELETED,
} from "./mutation_types";

const state = {
  pages: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  sections: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  loading: false,
  errors: [],
};
const getters = {
  pages(state) {
    return state.pages;
  },
  sections(state) {
    return state.sections;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_PAGE_SUCCESS](state, data) {
    state.pages = data;
  },
  [FETCH_PAGE_SECTION_SUCCESS](state, data) {
    state.sections = data;
  },
  [FETCH_PAGE_LOADING](state, status) {
    state.loading = status;
  },

  [FETCH_PAGE_DELETED](state, id) {
    state.pages.mdData = state.pages.mdData.filter((page) => page.id !== id);
  },

  [FETCH_PAGE_SECTION_DELETED](state, id) {
    state.sections.mdData = state.sections.mdData.filter(
      (section) => section.id !== id
    );
  },
};
const actions = {
  async getPages({ commit }, params) {
    try {
      commit(FETCH_PAGE_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getPages(params);
      commit(FETCH_PAGE_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PAGE_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_PAGE_LOADING, false);
    }
  },

  async createPage({ commit }, data) {
    try {
      await PR.createPage(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getPage({ commit }, id) {
    try {
      const section = await PR.getPage(id);
      return Promise.resolve(section);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updatePage({ commit }, data) {
    try {
      await PR.updatePage(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getSections({ commit }, params) {
    try {
      commit(FETCH_PAGE_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getSections(params);
      commit(FETCH_PAGE_SECTION_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PAGE_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_PAGE_LOADING, false);
    }
  },

  async createSection({ commit }, data) {
    try {
      await PR.createSection(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getSection({ commit }, id) {
    try {
      const section = await PR.getSection(id);
      return Promise.resolve(section);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateSection({ commit }, data) {
    try {
      await PR.updateSection(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deletePage({ commit }, id) {
    try {
      let response = await PR.deletePage(id);
      commit(FETCH_PAGE_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteSection({ commit }, id) {
    try {
      let response = await PR.deleteSection(id);
      commit(FETCH_PAGE_SECTION_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getFormData() {
    try {
      const data = await PR.getFormData();
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
