import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("request");
import {
  FETCH_PRODUCTS_FAILURE,
  DELETE_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_LOADING,
  PRODUCTS_STATUS_CHANGED,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_LOADING,
  PRODUCTS_ORDER_STATUS_CHANGED,
  FETCH_TRANSACTIONS_LOADING,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TAGS_LOADING,
  FETCH_TAGS_SUCCESS,
  FETCH_CATEGORIES_LOADING,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_ATTRIBUTES_LOADING,
  FETCH_ATTRIBUTES_SUCCESS,
  FETCH_BUYERS_SUCCESS,
  FETCH_BUYERS_LOADING,
  FETCH_PAYOUT_ORDERS_SUCCESS,
  ORDER_PAYMENT_APPROVED,
} from "./mutation_types";

const state = {
  products: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  orders: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  payout_orders: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  transactions: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  tags: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  buyers: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  attributes: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  categories: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  product: null,
  loading: false,
  errors: [],
};

const getters = {
  products(state) {
    return state.products;
  },
  loading(state) {
    return state.loading;
  },
  orders(state) {
    return state.orders;
  },
  transactions(state) {
    return state.transactions;
  },
  tags(state) {
    return state.tags;
  },
  categories(state) {
    return state.categories;
  },
  attributes(state) {
    return state.attributes;
  },
  buyers(state) {
    return state.buyers;
  },
  payout_orders(state) {
    return state.payout_orders;
  },
};

const mutations = {
  [FETCH_PRODUCTS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_PRODUCTS_SUCCESS](state, data) {
    state.products = data;
  },
  [FETCH_PRODUCTS_FAILURE](state, data) {
    state.errors = data;
  },
  [PRODUCTS_STATUS_CHANGED](state, { approval_status, id }) {
    state.products.mdData = state.products.mdData.map((product) => {
      if (product.id == id) {
        product.approval_status = approval_status;
      }
      return product;
    });
  },
  [FETCH_BUYERS_SUCCESS](state, data) {
    state.buyers = data;
  },
  PRODUCTS_ORDER_STATUS_CHANGED(state, data) {
    state.orders.mdData = state.orders.mdData.map((order) => {
      if (order.id == data.id) {
        if (data.payment_status) {
          order.payment_status = data.payment_status;
        }
      }
      return order;
    });
  },
  [DELETE_SUCCESS](state, id) {
    state.products.mdData = state.products.mdData.filter(
      (product) => product.id !== id
    );
  },
  [FETCH_ORDERS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_ORDERS_SUCCESS](state, data) {
    state.orders = data;
  },
  [FETCH_PAYOUT_ORDERS_SUCCESS](state, data) {
    state.payout_orders = data;
  },
  [FETCH_TRANSACTIONS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_TRANSACTIONS_SUCCESS](state, data) {
    state.transactions = data;
  },

  [FETCH_TAGS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_TAGS_SUCCESS](state, data) {
    state.tags = data;
  },
  [FETCH_CATEGORIES_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_CATEGORIES_SUCCESS](state, data) {
    state.categories = data;
  },
  [FETCH_ATTRIBUTES_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_ATTRIBUTES_SUCCESS](state, data) {
    state.attributes = data;
    a;
  },
  [FETCH_BUYERS_LOADING](state, data) {
    state.loading = data;
  },
};

const actions = {
  async getProducts({ commit }, params) {
    try {
      commit(FETCH_PRODUCTS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.products(params);
      commit(FETCH_PRODUCTS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PRODUCTS_LOADING, false);
      return data;
    } catch (error) {
      console.log(error);
      commit(FETCH_PRODUCTS_LOADING, false);
    }
  },
  async getProduct({ commit }, id) {
    try {
      const { data } = await PR.getProduct(id);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateStatus({ commit }, data) {
    try {
      await PR.updateStatus(data);
      commit(PRODUCTS_STATUS_CHANGED, data);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getOrders({ commit }, params) {
    try {
      commit(FETCH_ORDERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getOrders(params);
      commit(FETCH_ORDERS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_ORDERS_LOADING, false);
    } catch (err) {
      commit(FETCH_ORDERS_LOADING, false);
      return Promise.reject(err);
    }
  },
  async updateOrderStatus({ commit }, data) {
    try {
      let uData = { ...data };
      await PR.updateOrderStatus(data);
      commit(PRODUCTS_ORDER_STATUS_CHANGED, uData);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getTransactions({ commit }, params) {
    try {
      commit(FETCH_ORDERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getTransactions(params);
      commit(FETCH_TRANSACTIONS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_TRANSACTIONS_LOADING, false);
    } catch (err) {
      commit(FETCH_TRANSACTIONS_LOADING, false);
      Promise.reject(err);
    }
  },
  async getMetaData() {
    try {
      let response = await PR.getMetaData();
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getOrderMetadata(_, id) {
    try {
      let response = await PR.getOrderMetaData(id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async createProduct(_, data) {
    let formData = new FormData();

    for (var key in data) {
      if (key == "images") {
        for (let i = 0; i < data[key].length; i++) {
          formData.append("images", data[key][i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
    try {
      let response = await PR.createProduct(formData);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateProduct(_, data) {
    let formData = new FormData();

    for (var key in data) {
      if (key == "images") {
        for (let i = 0; i < data[key].length; i++) {
          formData.append("images[]", data[key][i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
    // formData.append("_method", "PATCH");
    try {
      let response = await PR.updateProduct(data.id, formData);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteProduct({ commit }, id) {
    try {
      let response = await PR.deleteProduct(id);
      commit(DELETE_SUCCESS, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getTags({ commit }, params) {
    try {
      commit(FETCH_TAGS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.tags(params);
      commit(FETCH_TAGS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_TAGS_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_TAGS_LOADING, false);
    }
  },
  async getCategories({ commit }, params) {
    try {
      commit(FETCH_CATEGORIES_LOADING, true);
      const {
        data: {
          categories: { total, data, current_page },
        },
      } = await PR.categories(params);
      commit(FETCH_CATEGORIES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_CATEGORIES_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_CATEGORIES_LOADING, false);
    }
  },
  async getBuyers({ commit }, params) {
    try {
      commit(FETCH_BUYERS_LOADING, true);

      const {
        buyers: { total, data, current_page },
      } = await PR.buyers(params);
      commit(FETCH_BUYERS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_BUYERS_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_BUYERS_LOADING, false);
    }
  },
  async getAttributes({ commit }, params) {
    try {
      commit(FETCH_ATTRIBUTES_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.attributes(params);
      commit(FETCH_ATTRIBUTES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_ATTRIBUTES_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_ATTRIBUTES_LOADING, false);
    }
  },
  async createAttr({ commit }, data) {
    try {
      await PR.createAttr(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateAttr({ commit }, data) {
    try {
      await PR.updateAttr(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async createTag({ commit }, data) {
    try {
      await PR.createTag(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateTag({ commit }, data) {
    try {
      await PR.updateTag(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async createCategory({ commit }, data) {
    try {
      await PR.createCategory(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateCategory({ commit }, data) {
    try {
      await PR.updateCategory(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteCategory({ commit }, id) {
    try {
      await PR.deleteCategory(id);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getAttr(_, id) {
    try {
      const { attribute } = await PR.getAttr(id);
      return Promise.resolve(attribute);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCat(_, id) {
    try {
      const { category } = await PR.getCat(id);
      return Promise.resolve(category);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getTag(_, id) {
    try {
      const { tag } = await PR.getTag(id);
      return Promise.resolve(tag);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteTag({ commit }, id) {
    try {
      await PR.deleteTag(id);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getCatMetadata() {
    try {
      let response = await PR.getCatMetaData();
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getTagMetadata() {
    try {
      let response = await PR.getTagMetaData();
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getPayoutOrders({ commit }, params) {
    try {
      commit(FETCH_ORDERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getPayoutOrders(params);
      commit(FETCH_PAYOUT_ORDERS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_ORDERS_LOADING, false);
    } catch (err) {
      commit(FETCH_ORDERS_LOADING, false);
      return Promise.reject(err);
    }
  },
  async approvePayment({ commit }, order_id) {
    try {
      commit(FETCH_ORDERS_LOADING, true);
      const data = await PR.approvePayment(order_id);
      commit(FETCH_ORDERS_LOADING, false);
      return Promise.resolve(data);
    } catch (err) {
      commit(FETCH_ORDERS_LOADING, false);
      return Promise.reject(err);
    }
  },
  async removeProductImage({ commit }, val) {
    try {
      const data = await PR.removeProductImage(val);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
