import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("faq");
import {
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_LOADING,
  FETCH_FAQ_DELETED,
  FETCH_FAQ_CATEGORY_SUCCESS,
  FETCH_FAQ_CATEGORY_DELETED,
} from "./mutation_types";

const state = {
  faq: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  categories: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  loading: false,
  errors: [],
};
const getters = {
  faq(state) {
    return state.faq;
  },
  categories(state) {
    return state.categories;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_FAQ_SUCCESS](state, data) {
    state.faq = data;
  },
  [FETCH_FAQ_CATEGORY_SUCCESS](state, data) {
    state.categories = data;
  },
  [FETCH_FAQ_LOADING](state, status) {
    state.loading = status;
  },
  [FETCH_FAQ_DELETED](state, id) {
    state.faq.mdData = state.faq.mdData.filter((faq) => faq.id !== id);
  },
  [FETCH_FAQ_CATEGORY_DELETED](state, id) {
    state.categories.mdData = state.categories.mdData.filter(
      (category) => category.id !== id
    );
  },
};
const actions = {
  async getFaqs({ commit }, params) {
    try {
      commit(FETCH_FAQ_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getFaqs(params);
      commit(FETCH_FAQ_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_FAQ_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_FAQ_LOADING, false);
    }
  },
  async getFaq(_, id) {
    try {
      const { faq } = await PR.getFaq(id);
      return Promise.resolve(faq);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async createFaq({ commit }, data) {
    try {
      await PR.createFaq(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateFaq({ commit }, data) {
    try {
      await PR.updateFaq(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getFormData(_, id) {
    try {
      const formData = await PR.getFormData(id);
      return Promise.resolve(formData);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCategories({ commit }, params) {
    try {
      commit(FETCH_FAQ_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getCategories(params);
      commit(FETCH_FAQ_CATEGORY_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_FAQ_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_FAQ_LOADING, false);
    }
  },

  async deleteFaq({ commit }, id) {
    try {
      let response = await PR.deleteFaq(id);
      commit(FETCH_FAQ_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getCategory(_, id) {
    try {
      const { category } = await PR.getCategory(id);
      return Promise.resolve(category);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async createCategory({ commit }, data) {
    try {
      await PR.createCategory(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateCategory({ commit }, data) {
    try {
      await PR.updateCategory(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteCategory({ commit }, id) {
    try {
      await PR.deleteCategory(id);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
