import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("request");
import { axios } from "./../../plugin/axios";
const state = {
  user: JSON.parse(localStorage.getItem("user")),
  token: JSON.parse(localStorage.getItem("token")),
  permissions: JSON.parse(localStorage.getItem("permissions")) || [],
};

const getters = {
  getToken(state) {
    return (state.token = JSON.parse(localStorage.getItem("token")));
  },
  getUser(state) {
    return (state.user = JSON.parse(localStorage.getItem("user")));
  },
  has_module_permission(state) {
    return (permit) => {
      // let has_permission = false;
      let has_permission = false;

      if (state.user.roles.map((role) => role.name).includes("administrator")) {
        has_permission = true;
      } else if (state.permissions && state.permissions.length > 0) {
        let permission = state.permissions.find(
          (permitted) => permitted == permit
        );

        has_permission = !!permission;
      }
      return has_permission;
    };
  },
};

const mutations = {
  login(state, data) {
    state.permissions = data.permissions;
    state.token = data.token;
    localStorage.setItem("permissions", JSON.stringify(data.permissions));
    localStorage.setItem("token", JSON.stringify(data.token));
    localStorage.setItem("user", JSON.stringify(data.user));
  },

  user(state, data) {
    state.user = data.user;
  },
  logout(state) {
    state.token = null;
    state.user = {};
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
  },
};

const actions = {
  async signIn({ commit, dispatch }, credentials) {
    try {
      const res = await PR.login(credentials);
      commit("login", res);
      commit("user", res);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async user({ commit }) {
    const res = await PR.user();
    commit("user", res);
    return res;
  },
  async export({ commit }, data) {
    const res = await PR.export(data).catch(function (error) {
      // console.log(error);
    });
    return res;
  },

  async logout({ commit }) {
    const res = await commit("logout");
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
