<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19.3419 14.49L18.3419 12.83C18.1319 12.46 17.9419 11.76 17.9419 11.35V8.82C17.9419 6.47 16.5619 4.44 14.5719 3.49C14.0519 2.57 13.0919 2 11.9919 2C10.9019 2 9.9219 2.59 9.4019 3.52C7.4519 4.49 6.1019 6.5 6.1019 8.82V11.35C6.1019 11.76 5.9119 12.46 5.7019 12.82L4.6919 14.49C4.2919 15.16 4.2019 15.9 4.4519 16.58C4.6919 17.25 5.2619 17.77 6.0019 18.02C7.9419 18.68 9.9819 19 12.0219 19C14.0619 19 16.1019 18.68 18.0419 18.03C18.7419 17.8 19.2819 17.27 19.5419 16.58C19.8019 15.89 19.7319 15.13 19.3419 14.49Z"
      fill="white"
    />
    <path
      d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
