import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("request");
import {
  FETCH_VENDOR_SUCCESS,
  FETCH_VENDOR_FAILURE,
  FETCH_VENDOR_LOADING,
  VENDOR_STATUS_CHANGED,
} from "./mutation_types";

const state = {
  vendors: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  vendor: null,
  loading: false,
  errors: [],
};
const getters = {
  vendors(state) {
    return state.vendors;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_VENDOR_SUCCESS](state, data) {
    state.vendors = data;
  },
  [FETCH_VENDOR_LOADING](state, status) {
    state.loading = status;
  },
  [VENDOR_STATUS_CHANGED](state, { approval_status, id }) {
    state.vendors.mdData = state.vendors.mdData.map((vendor) => {
      if (vendor.id == id) {
        vendor.approval_status = approval_status;
      }
      return vendor;
    });
  },
};
const actions = {
  async getVendors({ commit }, params) {
    try {
      commit(FETCH_VENDOR_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getVendors(params);
      commit(FETCH_VENDOR_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_VENDOR_LOADING, false);
    } catch (error) {
      // console.log(error);
      commit(FETCH_VENDOR_LOADING, false);
    }
  },

  async updateVendorStatus({ commit }, data) {
    try {
      await PR.updateVendorStatus(data);
      commit(VENDOR_STATUS_CHANGED, data);
    } catch (err) {
      Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
