import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("testimonial");
import {
  FETCH_TESTIMONIAL_LOADING,
  FETCH_TESTIMONIAL_SUCCESS,
  FETCH_TESTIMONIAL_FAILURE,
} from "./mutation_types";

const state = {
  testimonial: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },

  loading: false,
  errors: [],
};

const getters = {
  testimonial(state) {
    return state.testimonial;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_TESTIMONIAL_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_TESTIMONIAL_SUCCESS](state, data) {
    state.testimonial = data;
  },
  [FETCH_TESTIMONIAL_FAILURE](state, data) {
    state.errors = data;
  },

  ["TESTIMONIAL_DELETED"](state, id) {
    state.testimonial.mdData = state.testimonial.mdData.filter(
      (testimonial) => testimonial.id !== id
    );
  },
};

const actions = {
  async index({ commit }) {
    try {
      commit(FETCH_TESTIMONIAL_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.index();

      commit(FETCH_TESTIMONIAL_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_TESTIMONIAL_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_TESTIMONIAL_LOADING, false);
      console.log(error);
    }
  },

  async createTestimonial({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }
      await PR.createTestimonial(formData);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateTestimonial({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }
      formData.append("_method", "patch");
      await PR.updateTestimonial(data.id, formData);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getTestimonial({ commit }, params) {
    try {
      commit(FETCH_TESTIMONIAL_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getTestimonial(params);
      commit(FETCH_TESTIMONIAL_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_TESTIMONIAL_LOADING, false);
    } catch (err) {
      commit(FETCH_TESTIMONIAL_LOADING, false);

      return Promise.reject(err);
    }
  },

  async deleteTestimonial({ commit }, id) {
    try {
      let response = await PR.deleteTestimonial(id);
      commit("TESTIMONIAL_DELETED", id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
