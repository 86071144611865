<template>
  <div class="w-full">
    <header class="top">
      <h2>Handyman Job offers report</h2>
    </header>
    <div class="grid-user">
      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_buyers) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_buyers) | formatNumber
        "
        title_text="Total Job Offers"
        subtitle_text="This month registration"
        link_label="View job offers"
        link="/product/orders"
      >
        <template slot="icon">
          <StrongBoxIcon />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_handymen) | formatNumber"
        :subtitle="
          loading
            ? '--'
            : parseInt(data.total_month_professionals) | formatNumber
        "
        title_text="Total Page Views"
        subtitle_text="This month"
      >
        <template slot="icon">
          <EyeIcon2 />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_handymen) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_handymen) | formatNumber
        "
        title_text="Total Completed Jobs"
        subtitle_text="This month"
      >
        <template slot="icon">
          <CartIcon />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_vendors) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_vendors) | formatNumber
        "
        title_text="Total uncompleted Jobs"
        subtitle_text="This month"
      >
        <template slot="icon">
          <EyeIcon />
        </template>
      </stats-card-new>
    </div>
  </div>
</template>

<script>
import {
  StatsCardNew,
  EyeIcon,
  EyeIcon2,
  StrongBoxIcon,
  CartIcon,
} from "@/components";

export default {
  props: ["data", "loading"],
  components: {
    StatsCardNew,
    EyeIcon,
    EyeIcon2,
    StrongBoxIcon,
    CartIcon,
  },
};
</script>

<style></style>
