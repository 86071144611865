import { axios, url } from "@/plugin/axios";
var fileDownload = require("js-file-download");

export default {
  //methods
  login(data) {
    return axios.post(`${url}admin/login`, data);
  },
  user() {
    return axios.get(`${url}admin`);
  },
  index() {
    // return axios.get(`${url}admin/dashboard-data`);
  },

  async export(data) {
    const res = await axios.get(`${url}admin/export/${data.model}`, {
      responseType: "blob",
    });
    fileDownload(res, `${data.model}.xlsx`);
    return res;
  },

  getUserData() {
    return axios.get(`${url}admin/dashboard-user-data`);
  },
  getProductData() {
    return axios.get(`${url}admin/dashboard-product-data`);
  },
  getOrderData() {
    return axios.get(`${url}admin/dashboard-order-data`);
  },

  getNotifications(limit = null, params) {
    let limits = limit ? `?limit=${limit}` : "";
    return axios.get(`${url}admin/notifications${limits}`, {
      params: params,
    });
  },

  getUnreadNotificationsCount() {
    return axios.get(`${url}admin/notifications/unread`);
  },

  markNotifications() {
    return axios.post(`${url}admin/notifications/read`);
  },
  // Products management

  products(data) {
    return axios.get(`${url}products/admin`, { params: data });
  },

  updateStatus({ approval_status, id }) {
    return axios.patch(`${url}products/admin/update-status/${id}`, {
      approval_status,
    });
  },

  updateOrderStatus(data) {
    let id = data.id;
    delete data.id;
    return axios.patch(`${url}products/admin/orders/update/${id}`, {
      ...data,
    });
  },

  getOrderMetaData(id) {
    return axios.get(`${url}products/admin/orders/metadata/${id}`);
  },

  getOrders(data) {
    return axios.get(`${url}products/admin/orders`, { params: data });
  },
  getPayoutOrders(data) {
    return axios.get(`${url}products/admin/payouts`, { params: data });
  },
  approvePayment(order_id) {
    return axios.patch(`${url}products/admin/payouts/${order_id}`);
  },
  getTransactions(data) {
    return axios.get(`${url}products/admin/transactions`, { params: data });
  },

  getMetaData() {
    return axios.get(`${url}products/metadata`);
  },

  createProduct(data) {
    return axios.post(`${url}products/create`, data);
  },

  updateProduct(id, data) {
    return axios.post(`${url}products/update/${id}`, data);
  },

  getProduct(id) {
    return axios.get(`${url}products/admin/${id}`);
  },

  deleteProduct(id) {
    return axios.delete(`${url}products/admin/${id}`);
  },
  removeProductImage(data) {
    return axios.delete(`${url}products/admin/${data.model}/${data.filename}`);
  },

  // professional  management
  getProfessionals(data) {
    return axios.get(`${url}professional/admin`, { params: data });
  },
  // professional services
  getProfessionalServices(data) {
    return axios.get(`${url}professional/admin/services`, { params: data });
  },
  // update professional
  updateProfessionalStatus({ approval_status, id }) {
    return axios.patch(`${url}professional/admin/update/${id}`, {
      approval_status,
    });
  },
  getProServiceData() {
    return axios.get(`${url}professional/admin/services/metadata`);
  },
  proService(id) {
    return axios.get(`${url}professional/admin/services/${id}`);
  },

  createProService(data) {
    return axios.post(`${url}professional/admin/services/create`, data);
  },

  updateProService(id, data) {
    return axios.patch(`${url}professional/admin/services/${id}`, data);
  },

  deleteProService(id) {
    return axios.delete(`${url}professional/admin/services/${id}`);
  },

  updatePServiceStatus(data) {
    return axios.post(`${url}professional/admin/services/multi-service`, data);
  },

  // handymen

  // handymen  management
  getHandymen(data) {
    return axios.get(`${url}handymen/admin`, { params: data });
  },
  // handymen services
  getHandymanServices(data) {
    return axios.get(`${url}handymen/admin/services`, { params: data });
  },
  // update handymen
  updateHandymanStatus({ approval_status, id }) {
    return axios.patch(`${url}handymen/admin/update/${id}`, {
      approval_status,
    });
  },
  getHandymanServiceData() {
    return axios.get(`${url}handymen/admin/services/metadata`);
  },
  handymanService(id) {
    return axios.get(`${url}handymen/admin/services/${id}`);
  },

  createHandymanService(data) {
    return axios.post(`${url}handymen/admin/services/create`, data);
  },

  updateHandymanService(id, data) {
    return axios.patch(`${url}handymen/admin/services/${id}`, data);
  },

  deleteHandymanService(id) {
    return axios.delete(`${url}handymen/admin/services/${id}`);
  },

  updateHServiceStatus(data) {
    return axios.post(`${url}handymen/admin/services/multi-service`, data);
  },

  // Vendors

  // vendor  management
  getVendors(data) {
    return axios.get(`${url}vendor/admin`, { params: data });
  },

  // update vendor
  updateVendorStatus({ approval_status, id }) {
    return axios.patch(`${url}vendor/admin/update/${id}`, {
      approval_status,
    });
  },

  // Category management

  categories(data) {
    return axios.get(`${url}products/admin/categories`, { params: data });
  },
  createCategory(data) {
    return axios.post(`${url}products/admin/categories/create`, data);
  },
  updateCategory(id, data) {
    return axios.patch(`${url}products/admin/categories/update/${id}`, data);
  },
  getCat(id) {
    return axios.get(`${url}products/admin/categories/show/${id}`);
  },
  deleteCategory(id) {
    return axios.delete(`${url}products/admin/categories/${id}`);
  },
  getCatMetaData() {
    return axios.get(`${url}products/admin/categories/metadata`);
  },
  // Attributes management

  attributes(data) {
    return axios.get(`${url}products/admin/attributes`, { params: data });
  },
  createAttr(data) {
    return axios.post(`${url}products/admin/attributes/create`, data);
  },
  updateAttr(id, data) {
    return axios.patch(`${url}products/admin/attributes/update/${id}`, data);
  },
  getAttr(id) {
    return axios.get(`${url}products/admin/attributes/show/${id}`);
  },

  // Tag management
  tags(data) {
    return axios.get(`${url}products/admin/tags`, { params: data });
  },
  createTag(data) {
    return axios.post(`${url}products/admin/tags/create`, data);
  },
  updateTag(id, data) {
    return axios.patch(`${url}products/admin/tags/update/${id}`, data);
  },

  getTag(id) {
    return axios.get(`${url}products/admin/tags/show/${id}`);
  },
  getTagMetaData() {
    return axios.get(`${url}products/admin/tags/metadata`);
  },
  deleteTag(id) {
    return axios.delete(`${url}products/admin/tags/${id}`);
  },

  // Buyers management
  buyers(data) {
    return axios.get(`${url}products/admin/buyers`, { params: data });
  },
};
