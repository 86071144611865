var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_vm._m(0),_c('div',{staticClass:"grid-order"},[_c('StatsCardOrder',{attrs:{"title":"Confirmed Orders","total":_vm._f("formatNumber")(_vm.loading ? '--' : parseInt(_vm.data.total_confirmed_order)),"total_month":_vm._f("formatNumber")(_vm.loading
          ? '--'
          : parseInt(_vm.data.total_month_confirmed_order))}}),_c('StatsCardOrder',{attrs:{"title":"Shipped Orders","total":_vm._f("formatNumber")(_vm.loading ? '--' : parseInt(_vm.data.total_shipped_order)),"total_month":_vm._f("formatNumber")(_vm.loading
          ? '--'
          : parseInt(_vm.data.total_month_shipped_order))}}),_c('StatsCardOrder',{attrs:{"title":"Delivered Orders","total":_vm._f("formatNumber")(_vm.loading ? '--' : parseInt(_vm.data.total_delivered_order)),"total_month":_vm._f("formatNumber")(_vm.loading
          ? '--'
          : parseInt(_vm.data.total_month_delivered_order))}}),_c('StatsCardOrder',{attrs:{"title":"Received Orders","total":_vm._f("formatNumber")(_vm.loading ? '--' : parseInt(_vm.data.total_received_order)),"total_month":_vm._f("formatNumber")(_vm.loading
          ? '--'
          : parseInt(_vm.data.total_month_received_order))}}),_c('StatsCardOrder',{attrs:{"title":"Cancelled Orders","total":_vm._f("formatNumber")(_vm.loading ? '--' : parseInt(_vm.data.total_cancelled_order)),"total_month":_vm._f("formatNumber")(_vm.loading
          ? '--'
          : parseInt(_vm.data.total_month_cancelled_order))}}),_c('StatsCardOrder',{attrs:{"title":"Refunded Orders","total":_vm._f("formatNumber")(_vm.loading ? '--' : parseInt(_vm.data.total_refunded_order)),"total_month":_vm._f("formatNumber")(_vm.loading
          ? '--'
          : parseInt(_vm.data.total_month_refunded_order))}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"top"},[_c('h2',[_vm._v("Order Status report")])])}]

export { render, staticRenderFns }