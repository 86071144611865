<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#04A431" />
    <path
      d="M30.9557 38.2501C32.0833 38.2501 32.9974 37.336 32.9974 36.2084C32.9974 35.0808 32.0833 34.1667 30.9557 34.1667C29.8281 34.1667 28.9141 35.0808 28.9141 36.2084C28.9141 37.336 29.8281 38.2501 30.9557 38.2501Z"
      fill="white"
    />
    <path
      d="M21.6276 38.2501C22.7552 38.2501 23.6693 37.336 23.6693 36.2084C23.6693 35.0808 22.7552 34.1667 21.6276 34.1667C20.5 34.1667 19.5859 35.0808 19.5859 36.2084C19.5859 37.336 20.5 38.2501 21.6276 38.2501Z"
      fill="white"
    />
    <path
      d="M17.6493 16.5966L17.4159 19.4549C17.3693 20.0033 17.8009 20.4583 18.3493 20.4583H36.2109C36.7009 20.4583 37.1093 20.0849 37.1443 19.5949C37.2959 17.5299 35.7209 15.8499 33.6559 15.8499H19.3176C19.2009 15.3366 18.9676 14.8466 18.6059 14.4383C18.0226 13.8199 17.2059 13.4583 16.3659 13.4583H14.3359C13.8576 13.4583 13.4609 13.8549 13.4609 14.3333C13.4609 14.8116 13.8576 15.2083 14.3359 15.2083H16.3659C16.7276 15.2083 17.0659 15.3599 17.3109 15.6166C17.5559 15.8849 17.6726 16.2349 17.6493 16.5966Z"
      fill="white"
    />
    <path
      d="M35.9258 22.2083H18.0291C17.5391 22.2083 17.1425 22.5816 17.0958 23.0599L16.6758 28.1349C16.5125 30.1299 18.0758 31.8333 20.0708 31.8333H33.0441C34.7941 31.8333 36.3341 30.3983 36.4625 28.6483L36.8475 23.1999C36.8941 22.6633 36.4741 22.2083 35.9258 22.2083Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
