<template>
  <div class="w-full">
    <header class="top">
      <h2>Notifications</h2>
    </header>
    <div class="grid-notifications">
      <div class="action">
        <router-link to="/notifications">View all notifications</router-link>
      </div>
      <notification
        v-for="(notification, i) in data"
        :key="i"
        :notification="notification"
      />
    </div>
  </div>
</template>

<script>
import { Notification } from "@/components";

export default {
  props: ["data", "loading"],
  components: {
    Notification,
  },
  data: () => {
    return {};
  },
};
</script>

<style scoped lang="scss">
.action {
  display: flex;
  justify-content: end;
  margin: 20px 0px;
  a {
    color: #212121 !important;
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
  }
}
</style>
