import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
// import VueRouter from "vue-router";
import App from "./App";
import router from "./router";
import store from "./store";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import { mixin } from "./mixins";
import Pagination from "vue-pagination-2";
import { PageHeader, Wave, MdcButton } from "@/components";
import tinymce from "vue-tinymce-editor";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

// router setup
// import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";
import "bootstrap/dist/js/bootstrap";
// css
import css from "./assets/css/main.css";
// configure router
// const router = new VueRouter({
//   routes, // short for routes: routes
//   linkExactActiveClass: "nav-item active",
// });

Vue.prototype.$Chartist = Chartist;
Vue.prototype.$eventBus = new Vue();

// Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.use(store);

Vue.component("pagination", Pagination);
Vue.component("Wave", Wave);
Vue.component("PageHeader", PageHeader);
Vue.component("MdcButton", MdcButton);
Vue.component("tinymce", tinymce);

Vue.mixin(mixin);

Vue.filter("formatMoney", function (value) {
  return value.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    maximumFractionDigits: 0,
  });
});

Vue.filter("formatNumber", function (value) {
  return value.toLocaleString("en-NG", {
    maximumFractionDigits: 0,
  });
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
  }
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  data: {
    Chartist: Chartist,
  },
});
