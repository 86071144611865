import guest from '../../router/middleware/guest'

const publicRoutes = [
    {
        path:'/',
        component: () => import('./Layouts/layout.vue'),
        redirect: "login",
        children:[
          
            // {
            //     path:'register',
            //     component: () => import('./Register.vue'),
            //     meta: {
            //         middleware: [
            //             guest
            //         ]
            //     }

            // },
            
            {
                path:'login',
                component: () => import('./login.vue'),
                meta: {
                    middleware: [
                        guest
                    ]
                }

            }
        ]
    }
]

export default publicRoutes;