<template>
  <md-button :type="type" class="md-primary cb" :disabled="loading"
    ><slot></slot>
    <svg
      v-show="loading"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block; margin-left: 5px"
      width="20px"
      height="20px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="30"
        stroke="#ffffff"
        stroke-width="10"
        fill="none"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="30"
        stroke="#ce2727"
        stroke-width="8"
        stroke-linecap="round"
        fill="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;180 50 50;720 50 50"
          keyTimes="0;0.5;1"
        ></animateTransform>
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="1s"
          values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
          keyTimes="0;0.5;1"
        ></animate>
      </circle></svg
  ></md-button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "submit",
    },
  },
};
</script>

<style scoped lang="scss"></style>
