export const promotions = {
  HANDYMAN: 1,
  PROFESSIONAL: 2,
  REALESTATE: 3,
  PRODUCT: 4,
  ACCOUNT: 5,
  PORTFOLIO: 6,
};

export const notifications_type = {
  NEW_USER: "new_user",
  NEW_VENDOR: "new_vendor",
  NEW_ORDER: "new_order",
  NEW_PROFESSIONAL: "new_professional",
  NEW_HANDYMAN: "new_handyman",
  NEW_REALTOR: "new_realtor",
  NEW_PRODUCT: "new_product",
};
