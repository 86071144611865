import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const RE = Repo.get("realEstate");
import {
  FETCH_REALTORS_SUCCESS,
  FETCH_REALTORS_LOADING,
  FETCH_R_FEATURES_SUCCESS,
  FETCH_R_FEATURES_LOADING,
  FETCH_R_TYPES_SUCCESS,
  FETCH_R_TYPES_LOADING,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_LOADING,
  REALTORS_STATUS_CHANGED,
  PROPERTY_STATUS_CHANGED,
} from "./mutation_types";

const state = {
  realtors: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  properties: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  types: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  features: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  realtor: null,
  loading: false,
  errors: [],
};
const getters = {
  realtors(state) {
    return state.realtors;
  },
  types(state) {
    return state.types;
  },
  features(state) {
    return state.features;
  },
  properties(state) {
    return state.properties;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_REALTORS_SUCCESS](state, data) {
    state.realtors = data;
  },
  [FETCH_REALTORS_LOADING](state, status) {
    state.loading = status;
  },
  [FETCH_R_FEATURES_SUCCESS](state, data) {
    state.features = data;
  },
  [FETCH_R_FEATURES_LOADING](state, status) {
    state.loading = status;
  },
  [FETCH_R_TYPES_SUCCESS](state, data) {
    state.types = data;
  },
  [FETCH_R_TYPES_LOADING](state, status) {
    state.loading = status;
  },
  [FETCH_PROPERTY_SUCCESS](state, data) {
    state.properties = { ...state.properties, ...data };
  },
  [FETCH_PROPERTY_LOADING](state, status) {
    state.loading = status;
  },

  [REALTORS_STATUS_CHANGED](state, { approval_status, id }) {
    state.realtors.mdData = state.realtors.mdData.map((realtor) => {
      if (realtor.id == id) {
        realtor.approval_status = approval_status;
      }
      return realtor;
    });
  },
  [PROPERTY_STATUS_CHANGED](state, { approval_status, id }) {
    state.properties.mdData = state.properties.mdData.map((property) => {
      if (property.id == id) {
        property.approval_status = approval_status;
      }
      return property;
    });
  },
};
const actions = {
  async updateRealtorStatus({ commit }, data) {
    try {
      await RE.updateRealtorStatus(data);
      commit(REALTORS_STATUS_CHANGED, data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getProperties({ commit }, params) {
    try {
      commit(FETCH_PROPERTY_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await RE.getProperties(params);
      commit(FETCH_PROPERTY_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PROPERTY_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_PROPERTY_LOADING, false);
    }
  },
  async updatePropertyStatus({ commit }, data) {
    try {
      let response = await RE.updatePropertyStatus(data);
      commit(PROPERTY_STATUS_CHANGED, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createProperty(_, data) {
    try {
      await RE.createProperty(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateProperty(_, data) {
    try {
      await RE.updateProperty(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getFeatures({ commit }, params) {
    try {
      commit(FETCH_R_FEATURES_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await RE.getFeatures(params);
      commit(FETCH_R_FEATURES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_R_FEATURES_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_R_FEATURES_LOADING, false);
    }
  },
  async createFeature(_, data) {
    try {
      await RE.createFeature(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateFeature(_, data) {
    try {
      await RE.updateFeature(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getFeature(_, id) {
    try {
      const { feature } = await RE.getFeature(id);
      return Promise.resolve(feature);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getFormData(_, id) {
    try {
      const data = await RE.getFormData(id);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPropFormData(_) {
    try {
      const { data } = await RE.getPropFormData();
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTypes({ commit }, params) {
    try {
      commit(FETCH_R_TYPES_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await RE.getTypes(params);
      commit(FETCH_R_TYPES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_R_TYPES_LOADING, false);
      return data;
    } catch (error) {
      console.log(error);
      commit(FETCH_R_TYPES_LOADING, false);
    }
  },
  async createType(_, data) {
    try {
      await RE.createType(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateType(_, data) {
    try {
      await RE.updateType(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getType(_, id) {
    try {
      const { type } = await RE.getType(id);
      return Promise.resolve(type);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getRealtors({ commit }, params) {
    try {
      commit(FETCH_REALTORS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await RE.getRealtors(params);
      commit(FETCH_REALTORS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_REALTORS_LOADING, false);
      return data;
    } catch (error) {
      console.log(error);
      commit(FETCH_REALTORS_LOADING, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
