import auth from "../../router/middleware/auth";

const protectedRoutes = [
  {
    path: "/",
    component: () => import("./Layout/DashboardLayout.vue"),
    redirect: "/dashboard",

    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("./Dashboard.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("./notifications/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/professional-management",
        name: "Professional Management",
        component: () => import("./professional/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/professional-services",
        component: () => import("./professional/services.vue"),
        name: "Professional Services",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/professional-services/create",
        component: () => import("./professional/serviceForm.vue"),
        name: "Create Services",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/professional-services/edit/:id",
        component: () => import("./professional/serviceForm.vue"),
        name: "Edit Services",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/handyman-management",
        name: "Handyman Management",
        component: () => import("./handyman/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/handyman-services",
        component: () => import("./handyman/services.vue"),
        name: "Handyman Services",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/handyman-services/create",
        component: () => import("./handyman/serviceForm.vue"),
        name: "Create Handyman Services",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/handyman-services/edit/:id",
        component: () => import("./handyman/serviceForm.vue"),
        name: "Edit Handyman Services",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/vendor-management",
        name: "Vendor Management",
        component: () => import("./vendor/index.vue"),
        meta: {
          middleware: [auth],
        },
      },

      {
        path: "/products",
        name: "products",
        component: () => import("./product/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/products/create",
        name: "create-product",
        component: () => import("./product/create.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/products/:id/edit",
        name: "edit-product",
        component: () => import("./product/edit.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/orders",
        component: () => import("./product/orders.vue"),
        name: "product-orders",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/orders/payout",
        component: () => import("./product/payouts.vue"),
        name: "Order Payout",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/transactions",
        component: () => import("./product/transactions.vue"),
        meta: {
          middleware: [auth],
        },
        name: "transactions",
      },
      {
        path: "/product/buyers",
        component: () => import("./product/buyers.vue"),
        meta: {
          middleware: [auth],
        },
        name: "Buyers",
      },
      {
        path: "/product/attributes",
        name: "Product Attribute",
        component: () => import("./product/attribute/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/attributes/create",
        name: "Create Attribute",
        component: () => import("./product/attribute/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/attributes/:id/edit",
        name: "Edit Attribute",
        component: () => import("./product/attribute/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/tags",
        name: "Product Tags",
        component: () => import("./product/tag/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/tags/create",
        name: "Create Tag",
        component: () => import("./product/tag/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/tags/:id/edit",
        name: "Edit Tag",
        component: () => import("./product/tag/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/categories",
        name: "Product Categories",
        component: () => import("./product/category/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/category/create",
        name: "Create Category",
        component: () => import("./product/category/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/product/category/:id/edit",
        name: "Edit Category",
        component: () => import("./product/category/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "portfolios",
        name: "User Portfolios",
        component: () => import("./portfolio/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "user-management",
        name: "User Management",
        component: () => import("./user/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "users/login-location",
        name: "User Login Location",
        component: () => import("./user/loginLocations/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/users/create",
        name: "Create User",
        component: () => import("./user/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/users/:id",
        name: "Edit User Info",
        component: () => import("./user/edit.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/:id/edit",
        name: "Edit User",
        component: () => import("./user/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/roles",
        name: "Role Management",
        component: () => import("./user/role/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/role/create",
        name: "Create Role",
        component: () => import("./user/role/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/role/:id/edit",
        name: "Edit Role",
        component: () => import("./user/role/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/permission",
        name: "Permission Management",
        component: () => import("./user/permission/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/permission/create",
        name: "Create Permission",
        component: () => import("./user/permission/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/user/permission/:id/edit",
        name: "Edit Permission",
        component: () => import("./user/permission/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "affiliate-management",
        name: "Affiliate Management",
        component: () => import("./affiliate/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "affiliate-management/:id/sales",
        name: "Affiliate Management Sales",
        component: () => import("./affiliate/sales.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "affiliate/sales",
        name: "Affiliate Sales",
        component: () => import("./affiliate/sales.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/affiliate/payouts",
        name: "Affiliate Payouts",
        component: () => import("./affiliate/payouts.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/tags",
        component: () => import("./tags"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/categories",
        component: () => import("./categories"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/attribute",
        component: () => import("./attribute"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "adverts",
        name: "Adverts",
        component: () => import("./advert/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "adverts/create",
        name: "Create Advert",
        component: () => import("./advert/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "adverts/edit/:id",
        name: "Edit Advert",
        component: () => import("./advert//form.vue"),
        meta: {
          middleware: [auth],
        },
      },

      {
        path: "sections",
        name: "Advert Sections",
        component: () => import("./advert/section/index.vue"),
        meta: {
          middleware: [auth],
        },
      },

      {
        path: "advert/sections/create",
        name: "Create Advert Section",
        component: () => import("./advert/section/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "advert/sections/edit/:id",
        name: "Edit Advert Section",
        component: () => import("./advert/section/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/testimonial",
        name: "Testimonial",
        component: () => import("./testimonial/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/testimonial/create",
        name: "Create Testimonial",
        component: () => import("./testimonial/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/testimonial/:id/edit",
        name: "Edit Testimonial",
        component: () => import("./testimonial/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "commission",
        name: "Commission",
        component: () => import("./commission/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "commission/edit/:id",
        name: "Edit Commission",
        component: () => import("./commission/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate",
        name: "Estate Management",
        component: () => import("./estate/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/property/create",
        name: "Estate Create",
        component: () => import("./estate/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/realtors",
        name: "Estate Realtors",
        component: () => import("./estate/realtor.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/features",
        name: "Estate Features",
        component: () => import("./estate/features/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/features/create",
        name: "Create Features",
        component: () => import("./estate/features/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/features/edit/:id",
        name: "Edit Features",
        component: () => import("./estate/features/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/types",
        name: "Estate Types",
        component: () => import("./estate/types/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/types/create",
        name: "Create Types",
        component: () => import("./estate/types/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "estate/types/edit/:id",
        name: "Edit Types",
        component: () => import("./estate/types/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "site/pages-content",
        name: "Pages Content",
        component: () => import("./page/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "site/pages-content/create",
        name: "Pages Content Create",
        component: () => import("./page/form.vue"),
        meta: {
          middleware: [auth],
        },
      },

      {
        path: "site/pages-content/edit/:id",
        name: "Pages Content Edit",
        component: () => import("./page/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "site/pages-content/section",
        name: "Pages Section",
        component: () => import("./page/section/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "site/pages-content/section/create",
        name: "Pages Section Create",
        component: () => import("./page/section/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "site/pages-content//section/edit/:id",
        name: "Pages Section Edit",
        component: () => import("./page/section/form.vue"),
        meta: {
          middleware: [auth],
        },
      },

      // Faq Routes

      {
        path: "faq",
        name: "Faq",
        component: () => import("./faq/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "faq/create",
        name: "Faq Create",
        component: () => import("./faq/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "faq/edit/:id",
        name: "Faq Edit",
        component: () => import("./faq/form.vue"),
        meta: {
          middleware: [auth],
        },
      },

      // Faq Category Routes

      {
        path: "faq/category",
        name: "Faq Category",
        component: () => import("./faq/category/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "faq/category/create",
        name: "Faq Category Create",
        component: () => import("./faq/category/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "faq/category/edit/:id",
        name: "Faq Category Edit",
        component: () => import("./faq/category/form.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/promotions",
        name: "promotions",
        component: () => import("./promotions/index.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/buyers",
        component: () => import("./buyer/index.vue"),
        meta: {
          middleware: [auth],
        },
        name: "Buyers Management",
      },
    ],
  },
];

export default protectedRoutes;
