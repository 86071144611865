<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#6D26E1" />
    <path
      d="M37.5938 38.25H36.5V13.3125C36.5 12.5876 35.9124 12 35.1875 12H16.8125C16.0876 12 15.5 12.5876 15.5 13.3125V38.25H14.4062C14.0438 38.25 13.75 38.5438 13.75 38.9062V40H38.25V38.9062C38.25 38.5438 37.9562 38.25 37.5938 38.25ZM20.75 16.1562C20.75 15.7938 21.0438 15.5 21.4062 15.5H23.5938C23.9562 15.5 24.25 15.7938 24.25 16.1562V18.3438C24.25 18.7062 23.9562 19 23.5938 19H21.4062C21.0438 19 20.75 18.7062 20.75 18.3438V16.1562ZM20.75 21.4062C20.75 21.0438 21.0438 20.75 21.4062 20.75H23.5938C23.9562 20.75 24.25 21.0438 24.25 21.4062V23.5938C24.25 23.9562 23.9562 24.25 23.5938 24.25H21.4062C21.0438 24.25 20.75 23.9562 20.75 23.5938V21.4062ZM23.5938 29.5H21.4062C21.0438 29.5 20.75 29.2062 20.75 28.8438V26.6562C20.75 26.2938 21.0438 26 21.4062 26H23.5938C23.9562 26 24.25 26.2938 24.25 26.6562V28.8438C24.25 29.2062 23.9562 29.5 23.5938 29.5ZM27.75 38.25H24.25V33.6562C24.25 33.2938 24.5438 33 24.9062 33H27.0938C27.4562 33 27.75 33.2938 27.75 33.6562V38.25ZM31.25 28.8438C31.25 29.2062 30.9562 29.5 30.5938 29.5H28.4062C28.0438 29.5 27.75 29.2062 27.75 28.8438V26.6562C27.75 26.2938 28.0438 26 28.4062 26H30.5938C30.9562 26 31.25 26.2938 31.25 26.6562V28.8438ZM31.25 23.5938C31.25 23.9562 30.9562 24.25 30.5938 24.25H28.4062C28.0438 24.25 27.75 23.9562 27.75 23.5938V21.4062C27.75 21.0438 28.0438 20.75 28.4062 20.75H30.5938C30.9562 20.75 31.25 21.0438 31.25 21.4062V23.5938ZM31.25 18.3438C31.25 18.7062 30.9562 19 30.5938 19H28.4062C28.0438 19 27.75 18.7062 27.75 18.3438V16.1562C27.75 15.7938 28.0438 15.5 28.4062 15.5H30.5938C30.9562 15.5 31.25 15.7938 31.25 16.1562V18.3438Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
