<template>
  <div>
    <div class="heading">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.heading {
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
}
</style>
