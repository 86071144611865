import Vue from "vue";
import VueRouter from "vue-router";
import protectedRoutes from "../pages/private/router";
import publicRoutes from "../pages/public/router";
import store from "../store/index";
import middlewarePipeline from "../router/kernel/middlewarePipeline";

Vue.use(VueRouter);

const routes = [...protectedRoutes, ...publicRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const prevRoute = null;
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    //return next()
    return next((vm) => {
      vm.prevRoute = from;
    });
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
