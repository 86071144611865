import { axios, url } from "@/plugin/axios";

export default {
  create(data) {
    return axios.post(`${url}promotions/admin/create`, data);
  },
  update(id, data) {
    return axios.patch(`${url}promotions/admin/update/${id}`, data);
  },
  remove(id) {
    return axios.delete(`${url}promotions/admin/${id}`);
  },

  promotions(params) {
    return axios.get(`${url}promotions/admin`, {
      params: params,
    });
  },

  updatePayment(id, data) {
    return axios.patch(`${url}promotions/admin/update/payment/${id}`, data);
  },
};
