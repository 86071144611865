import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("affiliate");
import {
  FETCH_AFFILIATE_SUCCESS,
  FETCH_AFFILIATE_FAILURE,
  FETCH_AFFILIATE_LOADING,
  AFFILIATE_STATUS_CHANGED,
  FETCH_AFFILIATE_DELETED,
  FETCH_AFFILIATE_SALES_SUCCESS,
  FETCH_AFFILIATE_SALES_FAILURE,
  FETCH_AFFILIATE_PAYOUT_SUCCESS,
  FETCH_AFFILIATE_PAYOUT_FAILURE,
} from "./mutation_types";

const state = {
  affiliates: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  sales: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  payouts: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  loading: false,
  errors: [],
};
const getters = {
  affiliates(state) {
    return state.affiliates;
  },
  sales(state) {
    return state.sales;
  },
  payouts(state) {
    return state.payouts;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_AFFILIATE_SUCCESS](state, data) {
    state.affiliates = data;
  },
  [FETCH_AFFILIATE_SALES_SUCCESS](state, data) {
    state.sales = data;
  },
  [FETCH_AFFILIATE_PAYOUT_SUCCESS](state, data) {
    state.payouts = data;
  },
  [FETCH_AFFILIATE_LOADING](state, status) {
    state.loading = status;
  },

  [AFFILIATE_STATUS_CHANGED](state, { approval_status, id }) {
    console.log(id, "Got here");
    state.affiliates.mdData = state.affiliates.mdData.map((affiliate) => {
      if (affiliate.id == id) {
        affiliate.approval_status = approval_status;
      }
      return affiliate;
    });
  },
  [FETCH_AFFILIATE_DELETED](state, id) {
    state.services.mdData = state.services.mdData.filter(
      (service) => service.id !== id
    );
  },
};
const actions = {
  async getAffiliates({ commit }, params) {
    try {
      commit(FETCH_AFFILIATE_LOADING, true);

      const {
        data: { total, data, current_page },
      } = await PR.getAffiliates(params);
      commit(FETCH_AFFILIATE_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_AFFILIATE_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_AFFILIATE_LOADING, false);
    }
  },
  async getAffiliatesSales({ commit }, params) {
    try {
      commit(FETCH_AFFILIATE_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getAffiliatesSales(params);
      commit(FETCH_AFFILIATE_SALES_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_AFFILIATE_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_AFFILIATE_LOADING, false);
    }
  },

  async getAffiliatesRequests({ commit }, params) {
    try {
      commit(FETCH_AFFILIATE_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getAffiliatesRequests(params);
      commit(FETCH_AFFILIATE_PAYOUT_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_AFFILIATE_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(FETCH_AFFILIATE_LOADING, false);
    }
  },

  async approvePayout({ commit }, data) {
    try {
      await PR.approvePayout(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateAffiliateStatus({ commit }, data) {
    try {
      await PR.updateAffiliateStatus(data.id, data);
      commit(AFFILIATE_STATUS_CHANGED, data);
    } catch (err) {
      Promise.reject(err);
    }
  },

  async deleteAffiliate({ commit }, id) {
    try {
      let response = await PR.deleteAffiliate(id);
      commit(FETCH_AFFILIATE_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
