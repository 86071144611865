<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#0CB4C9" />
    <path
      d="M37.5625 22.4376C34.675 17.9001 30.45 15.2876 26 15.2876C23.775 15.2876 21.6125 15.9376 19.6375 17.1501C17.6625 18.3751 15.8875 20.1626 14.4375 22.4376C13.1875 24.4001 13.1875 27.5876 14.4375 29.5501C17.325 34.1001 21.55 36.7001 26 36.7001C28.225 36.7001 30.3875 36.0501 32.3625 34.8376C34.3375 33.6126 36.1125 31.8251 37.5625 29.5501C38.8125 27.6001 38.8125 24.4001 37.5625 22.4376ZM26 31.0501C23.2 31.0501 20.95 28.7876 20.95 26.0001C20.95 23.2126 23.2 20.9501 26 20.9501C28.8 20.9501 31.05 23.2126 31.05 26.0001C31.05 28.7876 28.8 31.0501 26 31.0501Z"
      fill="white"
    />
    <path
      d="M26 22.425C24.0375 22.425 22.4375 24.025 22.4375 26C22.4375 27.9625 24.0375 29.5625 26 29.5625C27.9625 29.5625 29.575 27.9625 29.575 26C29.575 24.0375 27.9625 22.425 26 22.425Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
