import { axios, url } from "@/plugin/axios";

export default {
  index() {
    return axios.get(`${url}products/admin/commission`);
  },
  createCommission(data) {
    return axios.post(`${url}products/admin/commission/create`, data);
  },
  updateCommission(id, data) {
    return axios.post(`${url}products/admin/commission/update/${id}`, data);
  },
  getCommission(id) {
    return axios.get(`${url}products/admin/commission/${id}`);
  },

  deleteCommission(id) {
    return axios.delete(`${url}products/admin/commission/${id}`);
  },
};
