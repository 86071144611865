<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#0CB4C9" />
    <path
      d="M36.6725 27.2031L38.9705 17.092C39.1364 16.362 38.5815 15.6668 37.8328 15.6668H18.7393L18.2937 13.4885C18.1827 12.9456 17.7049 12.5557 17.1507 12.5557H12.1667C11.5223 12.5557 11 13.078 11 13.7223V14.5001C11 15.1444 11.5223 15.6668 12.1667 15.6668H15.5638L18.9786 32.3615C18.1616 32.8314 17.6111 33.7123 17.6111 34.7223C17.6111 36.2258 18.8299 37.4446 20.3333 37.4446C21.8368 37.4446 23.0556 36.2258 23.0556 34.7223C23.0556 33.9604 22.7422 33.272 22.2377 32.7779H32.4289C31.9245 33.272 31.6111 33.9604 31.6111 34.7223C31.6111 36.2258 32.8299 37.4446 34.3333 37.4446C35.8368 37.4446 37.0556 36.2258 37.0556 34.7223C37.0556 33.6445 36.4291 32.7131 35.5205 32.2721L35.7887 31.092C35.9546 30.362 35.3997 29.6668 34.651 29.6668H21.6029L21.2847 28.1112H35.5349C36.0796 28.1112 36.5518 27.7343 36.6725 27.2031Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
