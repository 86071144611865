<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="8.5" stroke="#ED1C24" stroke-width="2" />
    <circle cx="9.5" cy="9.5" r="6.5" fill="#ED1C24" />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
