import { axios, url } from "@/plugin/axios";

export default {
  getTestimonial() {
    return axios.get(`${url}admin/testimonial`);
  },
  createTestimonial(data) {
    return axios.post(`${url}admin/testimonial`, data);
  },
  updateTestimonial(id, data) {
    return axios.patch(`${url}admin/testimonial/${id}`, data);
  },

  deleteTestimonial(id) {
    return axios.delete(`${url}admin/testimonial/${id}`);
  },
};
