import { axios, url } from "@/plugin/axios";

export default {
  createProperty(data) {
    return axios.post(`${url}estates/admin`, data);
  },
  updateProperty(id, data) {
    return axios.patch(`${url}estates/admin/property/${id}`, data);
  },
  getProperties(data) {
    return axios.get(`${url}estates/admin`, { params: data });
  },
  updatePropertyStatus({ id, approval_status }) {
    return axios.patch(`${url}estates/admin/update-status/${id}`, {
      approval_status,
    });
  },
  createType(data) {
    return axios.post(`${url}estates/admin/types/create`, data);
  },
  updateType(id, data) {
    return axios.patch(`${url}estates/admin/types/update/${id}`, data);
  },
  getTypes(data) {
    return axios.get(`${url}estates/admin/types`, { params: data });
  },
  getType(id) {
    return axios.get(`${url}estates/admin/types/show/${id}`);
  },
  createFeature(data) {
    return axios.post(`${url}estates/admin/features/create`, data);
  },
  updateFeature(id, data) {
    return axios.patch(`${url}estates/admin/features/update/${id}`, data);
  },
  getFeatures(id) {
    return axios.get(`${url}estates/admin/features`);
  },
  getFeature(id) {
    return axios.get(`${url}estates/admin/features/show/${id}`);
  },
  getFormData() {
    return axios.get(`${url}estates/admin/features/form-data`);
  },
  getPropFormData() {
    return axios.get(`${url}estates/admin/form-data`);
  },
  getRealtors(data) {
    return axios.get(`${url}realtor/admin`, { params: data });
  },
  updateRealtorStatus({ approval_status, id }) {
    return axios.patch(`${url}realtor/admin/update/${id}`, {
      approval_status,
    });
  },
};
