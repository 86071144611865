<template>
  <div class="notify" :class="{ 'not-read': !notification.read }">
    <div class="con">
      <div class="icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.0026 1.33325C4.32927 1.33325 1.33594 4.32659 1.33594 7.99992C1.33594 11.6733 4.32927 14.6666 8.0026 14.6666C11.6759 14.6666 14.6693 11.6733 14.6693 7.99992C14.6693 4.32659 11.6759 1.33325 8.0026 1.33325ZM7.5026 5.33325C7.5026 5.05992 7.72927 4.83325 8.0026 4.83325C8.27594 4.83325 8.5026 5.05992 8.5026 5.33325V8.66658C8.5026 8.93992 8.27594 9.16658 8.0026 9.16658C7.72927 9.16658 7.5026 8.93992 7.5026 8.66658V5.33325ZM8.61594 10.9199C8.5826 11.0066 8.53594 11.0733 8.47594 11.1399C8.40927 11.1999 8.33594 11.2466 8.25594 11.2799C8.17594 11.3133 8.08927 11.3333 8.0026 11.3333C7.91594 11.3333 7.82927 11.3133 7.74927 11.2799C7.66927 11.2466 7.59594 11.1999 7.52927 11.1399C7.46927 11.0733 7.4226 11.0066 7.38927 10.9199C7.35594 10.8399 7.33594 10.7533 7.33594 10.6666C7.33594 10.5799 7.35594 10.4933 7.38927 10.4133C7.4226 10.3333 7.46927 10.2599 7.52927 10.1933C7.59594 10.1333 7.66927 10.0866 7.74927 10.0533C7.90927 9.98658 8.09594 9.98658 8.25594 10.0533C8.33594 10.0866 8.40927 10.1333 8.47594 10.1933C8.53594 10.2599 8.5826 10.3333 8.61594 10.4133C8.64927 10.4933 8.66927 10.5799 8.66927 10.6666C8.66927 10.7533 8.64927 10.8399 8.61594 10.9199Z"
            fill="#939393"
          />
        </svg>
      </div>
      <div class="content">
        <h4>{{ notification.body }}</h4>
        <div class="date">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6693 8.49992C14.6693 12.1799 11.6826 15.1666 8.0026 15.1666C4.3226 15.1666 1.33594 12.1799 1.33594 8.49992C1.33594 4.81992 4.3226 1.83325 8.0026 1.83325C11.6826 1.83325 14.6693 4.81992 14.6693 8.49992Z"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.47 10.6199L8.40333 9.38659C8.04333 9.17326 7.75 8.65992 7.75 8.23992V5.50659"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span>{{ notification.created_at | formatDate }}</span>
        </div>
      </div>
    </div>
    <div class="action" v-if="getNotificationRoute(notification.module)">
      <router-link :to="getNotificationRoute(notification.module)">
        <button>View</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["notification"],
};
</script>

<style scoped lang="scss">
.notify {
  display: flex;
  height: 80px;
  padding: 14px 0px;
  border-bottom: 1px solid #f2f2f2;
  justify-content: space-between;
  align-items: center;
  .con {
    gap: 4px;
    display: flex;
  }

  .action {
    margin-right: 20px;

    button {
      display: flex;
      width: 102px;
      padding: 7px 33px 7px 34px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #212121;
      color: #fff;
    }
  }

  &.not-read {
    background: #f6f6f6;

    .icon {
      svg {
        path {
          fill: red;
        }
      }
    }
  }

  h4 {
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
  }

  .content {
    .date {
      display: flex;
      margin-top: 4px;
      gap: 5px;
      span {
        font-size: 14px;
        color: #212121;
      }
    }
  }
}
</style>
