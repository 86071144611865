<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#04A431" />
    <path
      d="M28.0078 26.2008V28.5933C28.0078 29.427 27.3311 30.1037 26.4974 30.1037C25.6636 30.1037 24.987 29.427 24.987 28.5933V26.2008C24.552 25.9349 24.2136 25.5483 23.972 25.0891H15.6224C14.9578 25.0891 14.4141 25.6329 14.4141 26.2974V30.5629C14.4141 34.4416 17.5557 37.5833 21.4345 37.5833H31.5482C35.4391 37.5833 38.5807 34.4416 38.5807 30.5629V26.2974C38.5807 25.6329 38.037 25.0891 37.3724 25.0891H29.0228C28.7811 25.5483 28.4428 25.9349 28.0078 26.2008Z"
      fill="white"
    />
    <path
      d="M31.5603 13.4167H21.4345C17.5557 13.4167 14.4141 16.5584 14.4141 20.4372V22.0684C14.4141 22.733 14.9578 23.2767 15.6224 23.2767H23.682C23.9599 21.6817 25.5428 20.5459 27.2707 21.0051C28.2374 21.2588 29.0228 22.0442 29.2766 23.0109C29.3007 23.0955 29.3007 23.1922 29.3128 23.2767H37.3724C38.037 23.2767 38.5807 22.733 38.5807 22.0684V20.4372C38.5807 16.5584 35.4391 13.4167 31.5603 13.4167Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
