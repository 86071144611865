import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("advertRequest");
import {
  FETCH_ADVERTS_LOADING,
  FETCH_ADVERTS_SUCCESS,
  FETCH_ADVERTS_FAILURE,
  FETCH_ADVERT_SECTIONS_LOADING,
  FETCH_ADVERT_SECTIONS_SUCCESS,
} from "./mutation_types";

const state = {
  adverts: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  sections: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  loading: false,
  errors: [],
};

const getters = {
  adverts(state) {
    return state.adverts;
  },
  loading(state) {
    return state.loading;
  },
  sections(state) {
    return state.sections;
  },
};

const mutations = {
  [FETCH_ADVERTS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_ADVERTS_SUCCESS](state, data) {
    state.adverts = data;
  },
  [FETCH_ADVERTS_FAILURE](state, data) {
    state.errors = data;
  },
  [FETCH_ADVERT_SECTIONS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_ADVERT_SECTIONS_SUCCESS](state, data) {
    state.sections = data;
  },
  ["ADVERT_DELETED"](state, id) {
    state.adverts.mdData = state.adverts.mdData.filter(
      (advert) => advert.id !== id
    );
  },
  ["SECTION_DELETED"](state, id) {
    state.sections.mdData = state.sections.mdData.filter(
      (section) => section.id !== id
    );
  },
};

const actions = {
  async getAdverts({ commit }, params) {
    try {
      commit(FETCH_ADVERTS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.adverts(params);

      commit(FETCH_ADVERTS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_ADVERTS_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_ADVERTS_LOADING, false);
    }
  },

  async getSections({ commit }, params) {
    try {
      commit(FETCH_ADVERT_SECTIONS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.sections(params);
      commit(FETCH_ADVERT_SECTIONS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_ADVERT_SECTIONS_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_ADVERT_SECTIONS_LOADING, false);
    }
  },
  async createAdvert({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }
      await PR.createAdvert(formData);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateAdvert({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }
      formData.append("_method", "patch");
      await PR.updateAdvert(data.id, formData);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async createSection({ commit }, data) {
    try {
      await PR.createSection(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateSection({ commit }, data) {
    try {
      await PR.updateSection(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getSection({ commit }, id) {
    try {
      const section = await PR.getSection(id);
      return Promise.resolve(section);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getAdvert({ commit }, id) {
    try {
      const res = await PR.getAdvert(id);
      return Promise.resolve(res.data.advert);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteAdvert({ commit }, id) {
    try {
      let response = await PR.deleteAdvert(id);
      commit("ADVERT_DELETED", id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteSection({ commit }, id) {
    try {
      let response = await PR.deleteSection(id);
      commit("SECTION_DELETED", id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getMetaData() {
    try {
      let response = await PR.getMetaData();
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
