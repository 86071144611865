<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#E8403D" />
    <path
      d="M31.3125 39.125C32.5206 39.125 33.5 38.1456 33.5 36.9375C33.5 35.7294 32.5206 34.75 31.3125 34.75C30.1044 34.75 29.125 35.7294 29.125 36.9375C29.125 38.1456 30.1044 39.125 31.3125 39.125Z"
      fill="white"
    />
    <path
      d="M21.3125 39.125C22.5206 39.125 23.5 38.1456 23.5 36.9375C23.5 35.7294 22.5206 34.75 21.3125 34.75C20.1044 34.75 19.125 35.7294 19.125 36.9375C19.125 38.1456 20.1044 39.125 21.3125 39.125Z"
      fill="white"
    />
    <path
      d="M17.05 15.925L16.8 18.9875C16.75 19.575 17.2125 20.0625 17.8 20.0625H36.9375C37.4625 20.0625 37.9 19.6625 37.9375 19.1375C38.1 16.925 36.4125 15.125 34.2 15.125H18.8375C18.7125 14.575 18.4625 14.05 18.075 13.6125C17.45 12.95 16.575 12.5625 15.675 12.5625H13.5C12.9875 12.5625 12.5625 12.9875 12.5625 13.5C12.5625 14.0125 12.9875 14.4375 13.5 14.4375H15.675C16.0625 14.4375 16.425 14.6 16.6875 14.875C16.95 15.1625 17.075 15.5375 17.05 15.925Z"
      fill="white"
    />
    <path
      d="M36.6376 21.9375H17.4626C16.9376 21.9375 16.5126 22.3375 16.4626 22.85L16.0126 28.2875C15.8376 30.425 17.5126 32.25 19.6501 32.25H33.5501C35.4251 32.25 37.0751 30.7125 37.2126 28.8375L37.6251 23C37.6751 22.425 37.2251 21.9375 36.6376 21.9375Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
