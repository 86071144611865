<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#FC930A" />
    <g clip-path="url(#clip0_4442_9797)">
      <path
        d="M38.25 27.75C38.25 23.3613 35.555 19.6059 31.7318 18.0364L29.5 22.5V16.375C29.5 16.1429 29.4078 15.9204 29.2437 15.7563C29.0796 15.5922 28.8571 15.5 28.625 15.5H23.375C23.1429 15.5 22.9204 15.5922 22.7563 15.7563C22.5922 15.9204 22.5 16.1429 22.5 16.375V22.5L20.2682 18.0364C16.445 19.6059 13.75 23.3613 13.75 27.75V31.25H38.25V27.75ZM39.125 33H12.875C12.6429 33 12.4204 33.0922 12.2563 33.2563C12.0922 33.4204 12 33.6429 12 33.875V35.625C12 35.8571 12.0922 36.0796 12.2563 36.2437C12.4204 36.4078 12.6429 36.5 12.875 36.5H39.125C39.3571 36.5 39.5796 36.4078 39.7437 36.2437C39.9078 36.0796 40 35.8571 40 35.625V33.875C40 33.6429 39.9078 33.4204 39.7437 33.2563C39.5796 33.0922 39.3571 33 39.125 33Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4442_9797">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
