<template>
  <div class="sidebar" :data-color="sidebarItemColor">
    <!-- :style="sidebarStyle" -->
    <!-- :data-image="sidebarBackgroundImage" -->
    <div class="logo">
      <a href="#" class="simple-text">
        <div class="">
          <img :src="imgLogo" alt="" style="width:200px; !important" />
        </div>
      </a>

      <a
        href="https://www.creative-tim.com/product/vue-material-dashboard"
        target="_blank"
        class="simple-text logo-normal"
      >
        {{ title }}
      </a>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    sidebarBackgroundImage: {
      type: String,
      default: require("@/assets/img/sidebar-2.jpg"),
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/baml-logo.png"),
    },
    sidebarItemColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`,
      };
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}

.sidebar-wrapper {
  &:hover {
    &::-webkit-scrollbar {
      /* visibility: visible; */
    }
  }
  &::-webkit-scrollbar {
    width: 4px;
    /* visibility: hidden; */
    transition: 0.3s;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #636363;
    outline: 1px solid #757575;
    border-radius: 10px;
  }
}
</style>
