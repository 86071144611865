<template>
  <div class="sk-wave" :style="style">
    <div :key="index" v-for="(_, index) in 5"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    name: {
      type: String,
      default: "three-bounce",
    },
    color: {
      type: String,
      default: "",
    },
    noFadeIn: {
      type: Boolean,
      default: false,
    },
    fadeIn: {
      type: String,
      default: "full",
    },
    className: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    style() {
      let color = this.color !== "" ? "color: " + this.color + ";" : "";
      let width = this.width !== "" ? "width: " + this.width + "px;" : "";
      let height = this.height !== "" ? "height: " + this.height + "px;" : "";
      return color + width + height;
    },
  },
};
</script>

<style scoped>
.sk-wave {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.sk-wave > div {
  background-color: currentColor;
  height: 100%;
  width: 6px;
  margin: 0 3px 0 0;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.sk-wave > div:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-wave > div:nth-child(3) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-wave > div:nth-child(4) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-wave > div:nth-child(5) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>
