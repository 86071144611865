import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("promotionRequest");
import {
  FETCH_PROMOTIONS_LOADING,
  FETCH_PROMOTIONS_SUCCESS,
  FETCH_PROMOTIONS_FAILURE,
} from "./mutation_types";

const state = {
  promotions: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
    categories: null,
  },
  promotion: null,
  loading: false,
  errors: [],
};

const getters = {
  allPromotions(state) {
    return state.promotions;
  },
  loading(state) {
    return state.loading;
  },
  promotion(state) {
    return state.promotion;
  },
};

const mutations = {
  [FETCH_PROMOTIONS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_PROMOTIONS_SUCCESS](state, data) {
    state.promotions = data;
  },
  [FETCH_PROMOTIONS_FAILURE](state, data) {
    state.errors = data;
  },
};

const actions = {
  async getPromotions({ commit }, params) {
    try {
      commit(FETCH_PROMOTIONS_LOADING, true);
      const {
        data: { total, data, current_page },
        categories,
      } = await PR.promotions(params);
      commit(FETCH_PROMOTIONS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
        categories: categories,
      });
      commit(FETCH_PROMOTIONS_LOADING, false);
      return data;
    } catch (error) {
      // console.log(error);
      commit(FETCH_PROMOTIONS_LOADING, false);
    }
  },
  async getPromotion({ commit }, id) {
    try {
      const { data } = await PR.getProduct(id);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createPromotion(_, data) {
    try {
      let response = await PR.create(data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePromotion(_, data) {
    try {
      let response = await PR.update(data.id, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePromotionPayment(_, data) {
    try {
      let response = await PR.updatePayment(data.id, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removePromotion(_, id) {
    try {
      let response = await PR.remove(id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
