import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("commission");
import {
  FETCH_COMMISSION_LOADING,
  FETCH_COMMISSION_SUCCESS,
  FETCH_COMMISSION_FAILURE,
} from "./mutation_types";

const state = {
  commission: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },

  loading: false,
  errors: [],
};

const getters = {
  commission(state) {
    return state.commission;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_COMMISSION_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_COMMISSION_SUCCESS](state, data) {
    state.commission = data;
  },
  [FETCH_COMMISSION_FAILURE](state, data) {
    state.errors = data;
  },

  ["COMMISSION_DELETED"](state, id) {
    state.commission.mdData = state.commission.mdData.filter(
      (commission) => commission.id !== id
    );
  },
};

const actions = {
  async index({ commit }) {
    console.log("this is from store");
    try {
      commit(FETCH_COMMISSION_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.index();

      commit(FETCH_COMMISSION_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_COMMISSION_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_COMMISSION_LOADING, false);
      console.log(error);
    }
  },

  async createCommission({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }
      await PR.createCommission(formData);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateCommission({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }
      formData.append("_method", "patch");
      await PR.updateCommission(data.id, formData);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getCommission({ commit }, id) {
    try {
      const commission = await PR.getCommission(id);
      return Promise.resolve(commission);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deleteCommission({ commit }, id) {
    try {
      let response = await PR.deleteCommission(id);
      commit("COMMISION_DELETED", id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
