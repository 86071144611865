import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("userRequest");
import {
  FETCH_USERS_LOADING,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_ROLES,
  FETCH_USERS_PERMISSIONS,
  FETCH_PORTFOLIOS_LOADING,
  FETCH_PORTFOLIOS_SUCCESS,
  PERMISSION_DELETED,
  FETCH_BUYERS_SUCCESS,
  FETCH_BUYERS_LOADING,
} from "./mutation_types";
// ROLE_DELETED,

const state = {
  users: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  buyers: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  portfolios: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  roles: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  permissions: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  user: null,
  loading: false,
  errors: [],
};

const getters = {
  users(state) {
    return state.users;
  },
  loading(state) {
    return state.loading;
  },
  user(state) {
    return state.user;
  },
  buyers(state) {
    return state.buyers;
  },
  portfolios(state) {
    return state.portfolios;
  },
  roles(state) {
    return state.roles;
  },
  permissions(state) {
    return state.permissions;
  },
};

const mutations = {
  [FETCH_USERS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_USERS_SUCCESS](state, data) {
    state.users = data;
  },
  [FETCH_USERS_FAILURE](state, data) {
    state.errors = data;
  },
  [FETCH_PORTFOLIOS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_BUYERS_SUCCESS](state, data) {
    state.buyers = data;
  },
  [FETCH_BUYERS_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_PORTFOLIOS_SUCCESS](state, data) {
    state.portfolios = data;
  },
  ["STATUS_CHANGED"](state, { approval_status, id }) {
    state.portfolios.mdData = state.portfolios.mdData.map((portfolio) => {
      if (portfolio.id == id) {
        portfolio.approval_status = approval_status;
      }
      return portfolio;
    });
  },
  ["PORTFOLIO_DELETED"](state, id) {
    state.portfolios.mdData = state.portfolios.mdData.filter(
      (portfolio) => portfolio.id !== id
    );
  },
  [FETCH_USERS_ROLES](state, data) {
    state.roles = data;
  },
  [FETCH_USERS_PERMISSIONS](state, data) {
    state.permissions = data;
  },
  [PERMISSION_DELETED](state, id) {
    state.permissions.mdData = state.permissions.mdData.filter(
      (permission) => permission.id !== id
    );
  },
};

const actions = {
  async getUsers({ commit }, params) {
    try {
      commit(FETCH_USERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.users(params);
      commit(FETCH_USERS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_USERS_LOADING, false);
      return data;
    } catch (error) {
      // console.log(error);
      commit(FETCH_USERS_LOADING, false);
    }
  },

  async createUser({ commit }, data) {
    try {
      let formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }

      await PR.createUser(formData, data.user_type);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateUser({ commit }, data) {
    const { id, ...rest } = data;
    try {
      await PR.updateUser(id, rest);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updatePasswordInfo({ commit }, data) {
    const { id, ...rest } = data;
    try {
      await PR.updatePasswordInfo(id, rest);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateRoleInfo({ commit }, data) {
    try {
      await PR.updateRoleInfo(data.id, data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getPortfolios({ commit }, params) {
    try {
      commit(FETCH_PORTFOLIOS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.portfolios(params);
      commit(FETCH_PORTFOLIOS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_PORTFOLIOS_LOADING, false);
      return data;
    } catch (error) {
      // console.log(error);
      commit(FETCH_USERS_LOADING, false);
    }
  },
  async updatePortfolioStatus({ commit }, data) {
    try {
      await PR.updatePortfolioStatus(data);
      commit("STATUS_CHANGED", data);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async deletePermissions({ commit }, id) {
    try {
      let response = await PR.deletePermissions(id);
      commit(PERMISSION_DELETED, id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deletePortfolio({ commit }, id) {
    try {
      let response = await PR.deletePortfolio(id);
      commit("PORTFOLIO_DELETED", id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getUserInfo({ commit }, id) {
    try {
      const user = await PR.getUserInfo(id);
      return Promise.resolve(user);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async loginAsUser({ commit }, id) {
    try {
      const res = await PR.loginAsUser(id);
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getRoles({ commit }, params) {
    try {
      commit(FETCH_USERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getRoles();
      commit(FETCH_USERS_ROLES, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_USERS_LOADING, false);
      return data;
    } catch (error) {
      // console.log(error);
      commit(FETCH_USERS_LOADING, false);
    }
  },
  async getRole(_, id) {
    try {
      const data = await PR.getRole(id);
      console.log(data);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPermissions({ commit }, params) {
    try {
      commit(FETCH_USERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.getPermissions(params);
      commit(FETCH_USERS_PERMISSIONS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_USERS_LOADING, false);
      return data;
    } catch (error) {
      // console.log(error);
      commit(FETCH_USERS_LOADING, false);
    }
  },
  async getPermission(_, id) {
    try {
      const { permission } = await PR.getPermission(id);
      return Promise.resolve(permission);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getFormData({ commit }) {
    try {
      const res = await PR.getFormData();
      return Promise.resolve(res.permissions);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getUFormData({ commit }) {
    try {
      const res = await PR.getUFormData();
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async createRole({ commit }, data) {
    try {
      await PR.createRole(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateRole({ commit }, data) {
    const { id, ...rest } = data;
    try {
      await PR.updateRole(id, rest);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async createPermission({ commit }, data) {
    try {
      await PR.createPermission(data);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updatePermission({ commit }, data) {
    const { id, ...rest } = data;
    try {
      await PR.updatePermission(id, rest);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getBuyers({ commit }, params) {
    try {
      commit(FETCH_BUYERS_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.buyers(params);
      commit(FETCH_BUYERS_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_BUYERS_LOADING, false);
      return data;
    } catch (error) {
      // console.log(error);
      commit(FETCH_BUYERS_LOADING, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
