import Swal from "sweetalert2/dist/sweetalert2.js";
import { notifications_type, promotions } from "@/constants";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const mixin = {
  data: () => ({
    promotions,
    notifications_type,
  }),
  methods: {
    parseError(err) {
      if (err.response.status === 422) {
        Swal.fire({
          title: "Validation Error",
          text:
            Object.values(err.response.data.errors)[0].msg ||
            err.response.data.msg,
          confirmButtonColor: "#d9534f",
          icon: "error",
        });
      } else if (err.response.status === 400) {
        Toast.fire({
          title: err.response.data.msg,
          icon: "error",
        });
      } else if (err.response.status === 404) {
        Toast.fire({
          title: err.response.data.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Oops,  something went wrong!",
          text: err.response.data.error.msg || "Internal error",
          confirmButtonColor: "#d9534f",
          icon: "error",
        });
      }
    },
    parseSuccess(msg) {
      Swal.fire({
        text: msg,
        confirmButtonColor: "#d9534f",
        icon: "success",
      });
    },
    parseErrorMsg(msg) {
      Swal.fire({
        title: "Oops,something went wrong",
        text: msg,
        confirmButtonColor: "#d9534f",
        icon: "error",
      });
    },
    toast(type, msg) {
      Toast.fire({
        icon: type,
        title: msg,
      });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    checkIfFilesAreTooBig() {
      let valid = true;
      if (this.fileSelected) {
        const size = this.fileSelected.size / 1024 / 1024;
        if (size > 1) {
          valid = false;
        }
      }
      return valid;
    },
    checkIfFilesAreCorrectType() {
      let valid = true;
      if (
        !["image/jpeg", "image/jpg", "image/png"].includes(
          this.fileSelected.type
        )
      ) {
        valid = false;
      }
      return valid;
    },
    getSN(index, current, limit) {
      return current == 1 ? index + 1 : current * limit + index + 1;
    },
    getStorageUrl(link) {
      return "https://buildingsandmore.ng" + link;
    },
    getNotificationRoute(type) {
      switch (type) {
        case notifications_type.NEW_USER:
          return "/user-management";
          break;
        case notifications_type.NEW_VENDOR:
          return "/vendor-management";
          break;
        case notifications_type.NEW_ORDER:
          return "/product/orders";
          break;
        case notifications_type.NEW_PROFESSIONAL:
          return "/professional-management";
          break;
        case notifications_type.NEW_HANDYMAN:
          return "/handyman-management";
          break;
        case notifications_type.NEW_REALTOR:
          return "/estate/realtors";
          break;
        case notifications_type.NEW_PRODUCT:
          return "/products";
          break;
        default:
          return "";
          break;
      }
    },
  },
};
