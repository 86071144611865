import { axios, url } from "@/plugin/axios";

export default {
  getAffiliates() {
    return axios.get(`${url}affiliate/admin`);
  },

  updateAffiliateStatus(id, data) {
    return axios.patch(`${url}affiliate/admin/${id}`, data);
  },

  deleteAffiliate(id) {
    return axios.delete(`${url}affiliate/admin/${id}`);
  },

  getAffiliatesSales(params) {
    if (params.id == null) {
      delete params.id;
    }
    return axios.get(`${url}affiliate/admin/sales`, { params });
  },
  getAffiliatesRequests() {
    return axios.get(`${url}affiliate/admin/requests`);
  },
  approvePayout(id, data) {
    return axios.patch(`${url}affiliate/admin/approve-payout/${id}`);
  },
};
