<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#04A431" />
    <path
      d="M38.5807 36.677C38.5807 37.1724 38.1699 37.5833 37.6745 37.5833H15.3203C14.8249 37.5833 14.4141 37.1724 14.4141 36.677C14.4141 36.1816 14.8249 35.7708 15.3203 35.7708H37.6745C38.1699 35.7708 38.5807 36.1816 38.5807 36.677Z"
      fill="white"
    />
    <path
      d="M30.5958 16.4616L17.6183 29.4391C17.1229 29.9345 16.3254 29.9345 15.8421 29.4391H15.83C14.1504 27.7475 14.1504 25.0166 15.83 23.337L24.4696 14.6975C26.1612 13.0058 28.8921 13.0058 30.5837 14.6975C31.0792 15.1687 31.0792 15.9783 30.5958 16.4616Z"
      fill="white"
    />
    <path
      d="M37.1545 21.2586L33.4691 17.5732C32.9736 17.0778 32.1761 17.0778 31.6928 17.5732L18.7153 30.5507C18.2199 31.0341 18.2199 31.8316 18.7153 32.327L22.4007 36.0245C24.0924 37.7041 26.8232 37.7041 28.5149 36.0245L37.1424 27.3849C38.8582 25.6932 38.8582 22.9503 37.1545 21.2586ZM27.4153 32.1699L25.9532 33.6441C25.6511 33.9461 25.1557 33.9461 24.8416 33.6441C24.5395 33.342 24.5395 32.8466 24.8416 32.5324L26.3157 31.0582C26.6057 30.7682 27.1132 30.7682 27.4153 31.0582C27.7174 31.3603 27.7174 31.8799 27.4153 32.1699ZM32.2124 27.3728L29.2641 30.3332C28.962 30.6232 28.4666 30.6232 28.1524 30.3332C27.8503 30.0311 27.8503 29.5357 28.1524 29.2216L31.1128 26.2611C31.4028 25.9711 31.9103 25.9711 32.2124 26.2611C32.5145 26.5753 32.5145 27.0707 32.2124 27.3728Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
