import { axios, url } from "@/plugin/axios";

export default {
  getPages() {
    return axios.get(`${url}pages/admin/content`);
  },

  createPage(data) {
    return axios.post(`${url}pages/admin/content`, data);
  },

  getPage(id) {
    return axios.get(`${url}pages/admin/content/${id}`);
  },
  updatePage(id, data) {
    return axios.patch(`${url}pages/admin/content/${id}`, data);
  },

  deletePage(id) {
    return axios.delete(`${url}pages/admin/content/${id}`);
  },

  createSection(data) {
    return axios.post(`${url}pages/admin/section/content`, data);
  },

  getSections() {
    return axios.get(`${url}pages/admin/section/content`);
  },

  getSection(id) {
    return axios.get(`${url}pages/admin/section/content/${id}`);
  },

  deleteSection(id) {
    return axios.delete(`${url}pages/admin/section/content/${id}`);
  },

  updateSection(id, data) {
    return axios.patch(`${url}pages/admin/section/content/${id}`, data);
  },

  getFormData() {
    return axios.get(`${url}pages/admin/section/form-data`);
  },
};
