<template>
  <div class="w-full">
    <header class="top">
      <h2>Order Status report</h2>
    </header>
    <div class="grid-order">
      <StatsCardOrder
        title="Confirmed Orders"
        :total="
          loading ? '--' : parseInt(data.total_confirmed_order) | formatNumber
        "
        :total_month="
          loading
            ? '--'
            : parseInt(data.total_month_confirmed_order) | formatNumber
        "
      />
      <StatsCardOrder
        title="Shipped Orders"
        :total="
          loading ? '--' : parseInt(data.total_shipped_order) | formatNumber
        "
        :total_month="
          loading
            ? '--'
            : parseInt(data.total_month_shipped_order) | formatNumber
        "
      />
      <StatsCardOrder
        title="Delivered Orders"
        :total="
          loading ? '--' : parseInt(data.total_delivered_order) | formatNumber
        "
        :total_month="
          loading
            ? '--'
            : parseInt(data.total_month_delivered_order) | formatNumber
        "
      />
      <StatsCardOrder
        title="Received Orders"
        :total="
          loading ? '--' : parseInt(data.total_received_order) | formatNumber
        "
        :total_month="
          loading
            ? '--'
            : parseInt(data.total_month_received_order) | formatNumber
        "
      />
      <StatsCardOrder
        title="Cancelled Orders"
        :total="
          loading ? '--' : parseInt(data.total_cancelled_order) | formatNumber
        "
        :total_month="
          loading
            ? '--'
            : parseInt(data.total_month_cancelled_order) | formatNumber
        "
      />
      <StatsCardOrder
        title="Refunded Orders"
        :total="
          loading ? '--' : parseInt(data.total_refunded_order) | formatNumber
        "
        :total_month="
          loading
            ? '--'
            : parseInt(data.total_month_refunded_order) | formatNumber
        "
      />
    </div>
  </div>
</template>

<script>
import { StatsCardOrder } from "@/components";

export default {
  props: ["data", "loading"],
  components: {
    StatsCardOrder,
  },
};
</script>

<style></style>
