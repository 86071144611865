<template>
  <div class="s-card">
    <div class="icon">
      <slot name="icon"></slot>
    </div>
    <div class="title">
      <h4>{{ title }}</h4>
      <p>{{ title_text }}</p>
    </div>

    <div class="subtitle">
      <h3>{{ subtitle }}</h3>
      <p>{{ subtitle_text }}</p>
    </div>

    <div class="action" v-if="link">
      <router-link :to="link">
        {{ link_label }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "stats-card-new",
  props: {
    title: {
      type: String,
      default: "",
    },
    title_text: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    subtitle_text: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    link_label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.s-card {
  background: #fff;
  padding: 14px;
  position: relative;
  width: 203px;
  height: 182px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #f2f2f2;

  background: #fff;

  .title {
    margin-top: 18px;

    h4 {
      margin: 0px;
      font-size: 18px;
      font-weight: 600;
      color: #212121;
      line-height: 184%;
    }
  }
  .subtitle {
    h3 {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
      color: #212121;
      line-height: 184%;
    }
  }
  p {
    color: #808080;
    font-size: 10px;
  }
  .icon {
    position: absolute;
    top: -26px;
    right: 15px;
  }
  .action {
    position: absolute;
    bottom: 12px;
    right: 15px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 184%; /* 18.4px */

    a {
      color: #ed1c24 !important;
      text-decoration-line: underline;
      &:hover {
        text-decoration-line: underline !important;
      }
    }
  }
}
</style>
