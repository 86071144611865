import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const API = Repo.get("request");
import {
  FETCH_DATA_LOADING,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
} from "./mutation_types";

const state = {
  productData: {},
  orderData: {},
  userData: {},
  notifications: [],
  notification_count: 0,
  all_notifications: {
    mdCount: 50,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },
  loading: false,
  p_loading: false,
  o_loading: false,
  u_loading: false,
  n_loading: false,
};

const getters = {
  productData(state) {
    return state.productData;
  },
  userData(state) {
    return state.userData;
  },
  orderData(state) {
    return state.orderData;
  },
  notifications(state) {
    return state.notifications;
  },
  all_notifications(state) {
    return state.all_notifications;
  },
  loading(state) {
    return state.loading;
  },
  p_loading(state) {
    return state.p_loading;
  },
  o_loading(state) {
    return state.o_loading;
  },
  u_loading(state) {
    return state.u_loading;
  },
  n_loading(state) {
    return state.n_loading;
  },
  count(state) {
    return state.notification_count;
  },
};

const mutations = {
  ["FETCH_P_LOADING"](state, data) {
    state.p_loading = data;
  },
  ["FETCH_P_SUCCESS"](state, data) {
    state.productData = data;
  },

  ["FETCH_O_LOADING"](state, data) {
    state.o_loading = data;
  },
  ["FETCH_O_SUCCESS"](state, data) {
    state.orderData = data;
  },
  ["FETCH_U_LOADING"](state, data) {
    state.u_loading = data;
  },
  ["FETCH_U_SUCCESS"](state, data) {
    state.userData = data;
  },
  ["FETCH_N_LOADING"](state, data) {
    state.n_loading = data;
  },
  ["FETCH_N_SUCCESS"](state, data) {
    state.notifications = data.notifications;
  },
  ["FETCH_ALL_N_SUCCESS"](state, data) {
    state.all_notifications = data;
  },
  ["FETCH_NOT_COUNT_SUCCESS"](state, data) {
    state.notification_count = data;
  },
};

const actions = {
  async getProductData({ commit }) {
    try {
      commit("FETCH_P_LOADING", true);
      const data = await API.getProductData();
      commit("FETCH_P_LOADING", false);
      commit("FETCH_P_SUCCESS", data);
      return data;
    } catch (error) {
      commit("FETCH_P_LOADING", false);
    }
  },
  async getOrderData({ commit }) {
    try {
      commit("FETCH_O_LOADING", true);
      const data = await API.getOrderData();
      commit("FETCH_O_LOADING", false);
      commit("FETCH_O_SUCCESS", data);
      return data;
    } catch (error) {
      commit("FETCH_O_LOADING", false);
    }
  },
  async getUserData({ commit }) {
    try {
      commit("FETCH_U_LOADING", true);
      const data = await API.getUserData();
      commit("FETCH_U_LOADING", false);
      commit("FETCH_U_SUCCESS", data);
      return data;
    } catch (error) {
      commit("FETCH_U_LOADING", false);
    }
  },
  async getNotifications({ commit }, limit) {
    try {
      commit("FETCH_N_LOADING", true);
      const data = await API.getNotifications(limit);
      commit("FETCH_N_LOADING", false);
      commit("FETCH_N_SUCCESS", data);
      return data;
    } catch (error) {
      commit("FETCH_N_LOADING", false);
    }
  },
  async getAllNotifications({ commit }, params) {
    try {
      commit("FETCH_N_LOADING", true);
      const {
        data: { total, data, current_page },
      } = await API.getNotifications(null, params);
      commit("FETCH_ALL_N_SUCCESS", {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit("FETCH_N_LOADING", false);
    } catch (err) {
      commit("FETCH_N_LOADING", false);
      Promise.reject(err);
    }
  },

  async getUnreadNotificationsCount({ commit }) {
    try {
      const data = await API.getUnreadNotificationsCount();
      commit("FETCH_NOT_COUNT_SUCCESS", data.count);
    } catch (err) {
      Promise.reject(err);
    }
  },

  async markNotifications({ commit }) {
    try {
      const data = await API.markNotifications();
      commit("FETCH_NOT_COUNT_SUCCESS", 0);
    } catch (err) {
      Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
