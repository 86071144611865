import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get("userLocation");
import {
  FETCH_USERLOCATION_LOADING,
  FETCH_USERLOCATION_SUCCESS,
  FETCH_USERLOCATION_FAILURE,
} from "./mutation_types";

const state = {
  usersLocation: {
    mdCount: 0,
    mdPage: 1,
    mdData: [],
    mdPerPage: 24,
  },

  loading: false,
  errors: [],
};

const getters = {
  userLocation(state) {
    return state.usersLocation;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [FETCH_USERLOCATION_LOADING](state, data) {
    state.loading = data;
  },
  [FETCH_USERLOCATION_SUCCESS](state, data) {
    state.usersLocation = data;
  },
  [FETCH_USERLOCATION_FAILURE](state, data) {
    state.errors = data;
  },

  ["USERLOCATION_DELETED"](state, id) {
    state.usersLocation.mdData = state.usersLocation.mdData.filter(
      (userLocation) => userLocation.id !== id
    );
  },
};

const actions = {
  async index({ commit }) {
    try {
      commit(FETCH_USERLOCATION_LOADING, true);
      const {
        data: { total, data, current_page },
      } = await PR.index();

      commit(FETCH_USERLOCATION_SUCCESS, {
        mdCount: total,
        mdPage: current_page,
        mdData: data,
      });
      commit(FETCH_USERLOCATION_LOADING, false);
      return data;
    } catch (error) {
      commit(FETCH_USERLOCATION_LOADING, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
